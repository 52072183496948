import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logout from "../images/logout.svg";
import listIcon from "../images/listIcon.svg";
import { color } from "chart.js/helpers";

function Header({ onLogout }) {
  const [baseURL, setBaseURL] = useState("https://kliqss.in/API");

  return (
    <header style={headerStyle}>
      <div className="headerLink">
        <NavLink to="/ListPage" style={headerImg}>
          <img src={listIcon} alt="List Icon" /> List
        </NavLink>
      </div>
      <div style={twoButtons}>
        <button onClick={onLogout} style={buttonStyle}>
          <img src={logout} alt="Logout Icon" />
        </button>
      </div>
    </header>
  );
}

const headerStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px",
  color: "white",
};

const headerImg = {
  display: "inline-block",
  display: "flex",
  alignItems: "center",
  gap: "6px",
  borderRadius: "50px",
};

const buttonStyle = {
  background: "none",
  border: "none",
  cursor: "pointer",
};

const twoButtons = {
  display: "flex",
  gap: "10px",
};

export default Header;

// import React, { useState, useEffect } from "react";
// import { NavLink } from "react-router-dom";
// import logout from "../images/logout.svg";
// import settings from "../images/settings.svg";
// import listIcon from "../images/listIcon.svg";
// import { color } from "chart.js/helpers";

// function Header({ onLogout }) {
//   const [isPopupOpen, setIsPopupOpen] = useState(false);
//   const [currentInput, setCurrentInput] = useState("");
//   const [baseURL, setBaseURL] = useState("");

//   // Load the baseURL from localStorage when the component mounts
//   useEffect(() => {
//     const savedBaseURL = localStorage.getItem("baseURL");
//     if (savedBaseURL) {
//       setBaseURL(savedBaseURL);
//     }
//   }, []);

//   const handleSave = () => {
//     if (currentInput) {
//       localStorage.setItem("baseURL", currentInput);
//       setBaseURL(currentInput); // Update saved value
//       setIsPopupOpen(false); // Close popup after saving
//     }
//   };

//   const handleOpenPopup = () => {
//     setCurrentInput(baseURL); // Set input to saved value when popup opens
//     setIsPopupOpen(true);
//   };

//   const handleCancel = () => {
//     setCurrentInput(baseURL); // Reset input to last saved value
//     setIsPopupOpen(false); // Close popup
//   };

//   return (
//     <header style={headerStyle}>
//       <div className="headerLink">
//         <NavLink to="/ListPage" style={headerImg}>
//           <img src={listIcon} alt="List Icon" /> List
//         </NavLink>
//       </div>
//       <div style={twoButtons}>
//         <button style={buttonStyle} onClick={handleOpenPopup}>
//           <img src={settings} alt="Settings Icon" />
//         </button>
//         <button onClick={onLogout} style={buttonStyle}>
//           <img src={logout} alt="Logout Icon" />
//         </button>
//       </div>

//       {isPopupOpen && (
//         <div style={popupStyle}>
//           <div style={popupContentStyle}>
//             <label htmlFor="baseURLInput">Enter Base URL:</label>
//             <input
//               type="text"
//               id="baseURLInput"
//               value={currentInput}
//               onChange={(e) => setCurrentInput(e.target.value)}
//               style={inputStyle}
//             />
//             <div style={twoButtons}>
//               <button onClick={handleSave} style={saveButtonStyle}>
//                 Save
//               </button>
//               <button onClick={handleCancel} style={cancelButtonStyle}>
//                 Cancel
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </header>
//   );
// }

// const headerStyle = {
//   display: "flex",
//   justifyContent: "space-between",
//   alignItems: "center",
//   padding: "10px",
//   color: "white",
// };

// const headerImg = {
//   display: "inline-block",
//   display: "flex",
//   alignItems: "center",
//   gap: "6px",
//   borderRadius: "50px",
// };

// const buttonStyle = {
//   background: "none",
//   border: "none",
//   cursor: "pointer",
// };

// const popupStyle = {
//   position: "fixed",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   backgroundColor: "#fff",
//   color: "#202020",
//   padding: "20px",
//   boxShadow: "0 0 10px rgba(0,0,0,0.2)",
//   zIndex: 1000,
// };

// const popupContentStyle = {
//   display: "flex",
//   flexDirection: "column",
//   gap: "10px",
//   fontSize: "14px",
// };

// const inputStyle = {
//   padding: "8px",
//   borderRadius: "4px",
//   border: "1px solid #ccc",
// };

// const saveButtonStyle = {
//   backgroundColor: "#185492",
//   color: "white",
//   padding: "5px",
//   border: "none",
//   borderRadius: "4px",
//   cursor: "pointer",
// };

// const cancelButtonStyle = {
//   backgroundColor: "#f44336",
//   color: "white",
//   padding: "5px",
//   border: "none",
//   borderRadius: "4px",
//   cursor: "pointer",
// };

// const twoButtons = {
//   display: "flex",
//   gap: "10px",
// };

// export default Header;

// import React, { useState } from "react";
// import { NavLink } from "react-router-dom";
// import logout from "../images/logout.svg";
// import settings from "../images/settings.svg";
// import listIcon from "../images/listIcon.svg";
// import { color } from "chart.js/helpers";

// function Header({ onLogout }) {
//   const [isPopupOpen, setIsPopupOpen] = useState(false);
//   const [baseURL, setBaseURL] = useState("");

//   const handleSave = () => {
//     if (baseURL) {
//       localStorage.setItem("baseURL", baseURL);
//       setIsPopupOpen(false); // Close popup after saving
//     }
//   };

//   return (
//     <header style={headerStyle}>
//       <div className="headerLink">
//         <NavLink to="/ListPage" style={headerImg}>
//           <img src={listIcon} alt="List Icon" /> List
//         </NavLink>
//       </div>
//       <button style={buttonStyle} onClick={() => setIsPopupOpen(true)}>
//         <img src={settings} alt="Settings Icon" />
//       </button>
//       <button onClick={onLogout} style={buttonStyle}>
//         <img src={logout} alt="Logout Icon" />
//       </button>

//       {isPopupOpen && (
//         <div style={popupStyle}>
//           <div style={popupContentStyle}>
//             <label htmlFor="baseURLInput">Enter Base URL:</label>
//             <input
//               type="text"
//               id="baseURLInput"
//               value={baseURL}
//               onChange={(e) => setBaseURL(e.target.value)}
//               style={inputStyle}
//             />
//             <button onClick={handleSave} style={saveButtonStyle}>
//               Save
//             </button>
//             <button
//               onClick={() => setIsPopupOpen(false)}
//               style={cancelButtonStyle}
//             >
//               Cancel
//             </button>
//           </div>
//         </div>
//       )}
//     </header>
//   );
// }

// const headerStyle = {
//   display: "flex",
//   justifyContent: "space-between",
//   alignItems: "center",
//   padding: "10px",
//   color: "white",
// };

// const headerImg = {
//   display: "inline-block",
//   display: "flex",
//   alignItems: "center",
//   gap: "6px",
//   borderRadius: "50px",
// };

// const buttonStyle = {
//   background: "none",
//   border: "none",
//   cursor: "pointer",
// };

// const popupStyle = {
//   position: "fixed",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   backgroundColor: "#fff",
//   color: "#202020",
//   padding: "20px",
//   boxShadow: "0 0 10px rgba(0,0,0,0.2)",
//   zIndex: 1000,
// };

// const popupContentStyle = {
//   display: "flex",
//   flexDirection: "column",
//   gap: "10px",
// };

// const inputStyle = {
//   padding: "8px",
//   fontSize: "16px",
//   borderRadius: "4px",
//   border: "1px solid #ccc",
// };

// const saveButtonStyle = {
//   backgroundColor: "#185492",
//   color: "white",
//   padding: "10px",
//   border: "none",
//   borderRadius: "4px",
//   cursor: "pointer",
// };

// const cancelButtonStyle = {
//   backgroundColor: "#f44336",
//   color: "white",
//   padding: "10px",
//   border: "none",
//   borderRadius: "4px",
//   cursor: "pointer",
// };

// export default Header;

// import React from "react";
// import { NavLink, useNavigate } from "react-router-dom";
// import logout from "../images/logout.svg";
// import settings from "../images/settings.svg";
// import listIcon from "../images/listIcon.svg";

// function Header({ onLogout }) {
//   return (
//     <header style={headerStyle}>
//       <div className="headerLink">
//         <NavLink to="/ListPage" style={headerImg}>
//           <img src={listIcon} /> List
//         </NavLink>
//       </div>
//       <button style={buttonStyle}>
//         <img src={settings} />
//       </button>
//       <button onClick={onLogout} style={buttonStyle}>
//         <img src={logout} />
//       </button>
//     </header>
//   );
// }

// const headerStyle = {
//   display: "flex",
//   justifyContent: "space-between",
//   alignItems: "center",
//   padding: "10px",
//   // backgroundColor: "#282c34",
//   color: "white",
// };

// const headerImg = {
//   display: "inline-block",
//   display: "flex",
//   alignItems: "center",
//   gap: "6px",
//   borderRadius: "50px",
// };

// const buttonStyle = {
//   // padding: "8px 16px",
//   // fontSize: "16px",
//   background: "none",
//   // color: "#fff",
//   border: "none",
//   cursor: "pointer",
// };

// export default Header;
