import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import debounce from "lodash.debounce";
import "./styles/Form.css";
import "./styles/ItemsList.css";
import "./styles/AccountAndTransactions.css";
import "./styles/Popup.css";
import logo from "../images/Logo.jpg";
import preview from "../images/preview.svg";
import editIcon from "../images/edit.svg";
import trashIcon from "../images/trash.svg";
import sale from "../images/sale.svg";
import ModalWindow from "./ModalWindow";
import ChargeModal from "./ChargeModal";
import { Buffer } from "buffer";
import { Capacitor } from "@capacitor/core";
import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";

// Polyfill for Buffer
window.Buffer = Buffer;

// Utility function to check if Cordova Printer Plugin is available
const isCordovaPrinterAvailable = () => {
  return (
    window.cordova &&
    window.cordova.plugins &&
    window.cordova.plugins.printer &&
    typeof window.cordova.plugins.printer.print === "function"
  );
};

// Styles for the WeightListPopup
const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  popup: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    width: "80%",
    maxHeight: "90%",
    overflowY: "auto",
    position: "relative",
    boxShadow: "0 5px 15px rgba(0,0,0,0.3)",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "15px",
    background: "transparent",
    border: "none",
    fontSize: "24px",
    cursor: "pointer",
  },
  printButton: {
    background: "#185492",
    color: "#fff",
    fontSize: "14px",
    border: "none",
    padding: "10px 15px",
    cursor: "pointer",
    boxShadow: "0px 5px 15px rgba(0,0,0,0.3)",
    marginBottom: "20px",
  },
  contentContainer: {
    textAlign: "left",
  },
  header: {
    textAlign: "center",
    marginBottom: "20px",
  },
  table: {
    borderTop: "2px dashed #202020",
    borderBottom: "2px dashed #202020",
    borderCollapse: "collapse",
    width: "100%",
    marginTop: "10px",
    marginBottom: "10px",
  },
  tbody: {
    borderTop: "2px dashed #202020",
    borderBottom: "2px dashed #202020",
    textAlign: "center",
  },

  totalNetWeightRow: {
    background: "white",
    borderTop: "2px dashed #202020",
  },
};

// Internal WeightListPopup Component
const WeightListPopup = ({ onClose, itemData }) => {
  const handlePrint = () => {
    const printContent = document.getElementById(
      "weight-list-popup-content"
    ).innerHTML;

    if (Capacitor.isNativePlatform()) {
      if (isCordovaPrinterAvailable()) {
        window.cordova.plugins.printer.print(
          printContent,
          "Print Document",
          function (res) {
            if (res) {
              console.log("Print Successful");
            } else {
              console.error("Print Failed");
              alert("Failed to print the document.");
            }
          }
        );
      } else {
        console.error("Cordova Printer Plugin not available.");
        alert("Printing is not supported on this device.");
      }
    } else {
      let iframeDoc = document.createElement("iframe");
      iframeDoc.style.position = "fixed";
      iframeDoc.style.right = "0";
      iframeDoc.style.bottom = "0";
      iframeDoc.style.width = "0";
      iframeDoc.style.height = "0";
      iframeDoc.style.border = "0";
      document.body.appendChild(iframeDoc);

      let iframeDocument = iframeDoc.contentWindow || iframeDoc.contentDocument;
      if (iframeDocument.document) iframeDocument = iframeDocument.document;

      iframeDocument.open();
      iframeDocument.write(`
        <html>
          <head>
            <title>Print Document</title>
            <style>
              body { font-family: Arial, sans-serif; padding: 20px; }
              table { width: 100%; border-collapse: collapse; }
              th, td { border: 1px solid #000; padding: 8px; text-align: left; }
              th { background-color: #f2f2f2; }
            </style>
          </head>
          <body>
            ${printContent}
          </body>
        </html>
      `);
      iframeDocument.close();

      iframeDoc.onload = () => {
        setTimeout(() => {
          iframeDoc.contentWindow.focus();
          iframeDoc.contentWindow.print();
        }, 500);
      };
    }
  };

  if (!itemData) return null;

  return (
    <div id="popup-overlay" style={styles.overlay}>
      <div className="weight-list-popup" style={styles.popup}>
        <button
          className="close-button"
          onClick={onClose}
          style={styles.closeButton}
        >
          &times;
        </button>
        <button
          className="print-button"
          onClick={handlePrint}
          style={styles.printButton}
        >
          Print
        </button>
        <div
          className="weight_list_container"
          id="weight-list-popup-content"
          style={styles.contentContainer}
        >
          <h2 style={styles.header}>AC Sale</h2>
          <p>
            <strong>Bill No:</strong> {itemData.billNo2}
          </p>
          <p>
            <strong>Bill Date:</strong> {itemData.billDate}
          </p>
          <p>
            <strong>Bond No:</strong> {itemData.bondNo}
          </p>
          <p>
            <strong>FSP:</strong> {itemData.fspno}
          </p>
          <p>
            {/* <strong>Party Name:</strong> {itemData.partyName} / {itemData.place} */}
            <strong>Party Name:</strong> {itemData.partyName}
          </p>
          <p>
            <strong>Farmer Name:</strong> {itemData.farmerName}
          </p>
          <p>
            <strong>S/o:</strong> {itemData.sOn}
          </p>
          <p>
            <strong>Place:</strong> {itemData.place}
          </p>
          <p>
            <strong>Product Name:</strong> {itemData.productName}
          </p>
          <p>
            <strong>Grade Name:</strong> {itemData.gradeName}
          </p>
          <p>
            <strong>LOT NO:</strong> {itemData.lotNo}
          </p>
          <p>
            <strong>Quality Mark:</strong> {itemData.qualityMark}
          </p>
          <p>
            <strong>Rate:</strong> {itemData.bagRate}
          </p>
          <p>
            <strong>Gross Weight:</strong> {itemData.grossWeight}
          </p>
          <p>{/* <strong>Bags Weight:</strong> {itemData.bagsWeight} */}</p>
          <p>
            <strong>Tare Weight:</strong> {itemData.bagsWeight}
          </p>
          <p>
            <strong>Net Weight:</strong> {itemData.netWeight}
          </p>
          <br />
          <p>
            <strong>Amount:</strong> Rs. {itemData.amount}
          </p>
          {/* <p>
            <strong>Bag Amount:</strong> Rs. {itemData.bagAmount}
          </p> */}
          {/* <p>
            <strong>Gunny Amount:</strong> Rs. {itemData.bagAmount}
          </p>
          <p>
            <strong>Total:</strong> Rs. {itemData.total}
          </p> */}
          <table style={styles.table}>
            {/* <thead>
              <tr>
                <th scope="col">S.NO</th>
                <th scope="col">Quantity(Kg)</th>
              </tr>
            </thead> */}
            <tbody style={styles.tbody}>

            <tr>
                <th scope="col">S.NO</th>
                <th scope="col">Quantity(Kg)</th>
              </tr>

              {itemData.serialNumbers.map((serial, index) => (
                <tr key={index}>
                  <td scope="row">{serial}</td>
                  <td>{itemData.weights[index]}</td>
                </tr>
              ))}

              <tr style={styles.totalNetWeightRow}>
                <th scope="row">Net Wgt:</th>
                <th scope="row" className="total-Qty">
                  {itemData.netWeight}
                </th>
              </tr>
            </tbody>
            <tfoot>
              {/* <tr>
                <th scope="row">Net Wgt:</th>
                <th scope="row" className="total-Qty">
                  {itemData.netWeight}
                </th> */}
              {/* </tr> */}
              {/* <tr>
                <th scope="row">Net Wgt:</th>
                <th scope="row" className="total-Qty">
                  {itemData.netWeight}
                </th>
              </tr> */}
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
};

function AcSale() {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedItemData, setSelectedItemData] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [farmers, setFarmers] = useState([]);
  const [farmerSuggestions, setFarmerSuggestions] = useState([]);
  const [billNo2, setBillNo2] = useState(
    localStorage.getItem("billNo2") || "ACS-1"
  );
  const [billDate, setBillDate] = useState(() => {
    const currentDate = new Date();
    return `${String(currentDate.getDate()).padStart(2, "0")}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}-${currentDate.getFullYear()}`;
  });
  const [selectedDate, setSelectedDate] = useState(
    localStorage.getItem("selectedDate") || billDate
  );
  const [partyName, setPartyName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [serialCount, setSerialCount] = useState(0);
  const [weightInput, setWeightInput] = useState("0.000");
  const [tareWeight, setTareWeight] = useState(
    localStorage.getItem("tareWeight") || "0.00"
  );
  const [netWeight, setNetWeight] = useState(
    localStorage.getItem("netWeight") || "0.00"
  );
  const [bags, setBags] = useState("");
  const [rate, setRate] = useState("");
  const [amount, setAmount] = useState("0.00");
  const [coolieCommission, setCoolieCommission] = useState("0.00");
  const [commission, setCommission] = useState("0.00");
  const [gAmt, setGAmt] = useState("0");
  const [netAmt, setNetAmt] = useState("0.00");
  const [fspNo, setFspNo] = useState("");
  const [bondNo, setBondNo] = useState("");
  const [transactionItems3, setTransactionItems3] = useState([]);
  const [itemName, setItemName] = useState("");
  const [itemSuggestions, setItemSuggestions] = useState([]);
  const [mark, setMark] = useState("");
  const [dropdown, setDropdown] = useState("");
  const [town, setTown] = useState("");
  const [farmer, setFarmer] = useState("");
  const [actName, setactName] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [partyNameValid, setPartyNameValid] = useState(true);
  const [itemNameValid, setItemNameValid] = useState(true);
  const [gAmtMultiplier, setGAmtMultiplier] = useState(0);
  const [transactionTypes, setTransactionTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chargeModalOpen, setChargeModalOpen] = useState(false);
  const [charges3, setCharges3] = useState([
    {
      id: 1,
      chargeName: "TCS P",
      rate: "0.100",
      value: "0.000",
      adjAcName: "TCS Purchase A/C",
      gst: "0",
      gstAmt: "0.00",
      total: "0.000",
    },
    {
      id: 2,
      chargeName: "Round off",
      rate: "1.000",
      value: "0.000",
      adjAcName: "ROUND OFF",
      gst: "0",
      gstAmt: "0.00",
      total: "0.000",
    },
  ]);
  const [buttonText, setButtonText] = useState("Save & New");
  const [weightEditable, setWeightEditable] = useState(false);

  const [gradeOptions, setGradeOptions] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState("");
  const [itemId, setItemId] = useState("");

  const [modalData, setModalData] = useState(
    JSON.parse(localStorage.getItem("acSaleModalData")) || {
      weights: [],
      startFrom: 1,
    }
  );

  const navigate = useNavigate();
  const partyNameRef = useRef(null);
  const itemNameRef = useRef(null);
  const markRef = useRef(null);
  const bagsRef = useRef(null);
  const weightRef = useRef(null);
  const rateRef = useRef(null);
  const dropdownRef = useRef(null);
  const townRef = useRef(null);
  const farmerRef = useRef(null);
  const dateRef = useRef(null);

  // Retrieve compid from localStorage
  const compid = localStorage.getItem("selectedCompany") || "";

  const api = useMemo(() => {
    const baseURL = "https://Kliqss.in/CSAPI";
    const instance = axios.create({
      baseURL: baseURL,
    });

    instance.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("token");
        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        // Attach compid to every request as a query parameter if available
        if (compid) {
          config.params = config.params || {};
          config.params.compid = compid;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    return instance;
  }, [compid]);

  const checkForExistingData = (partyName, billDate) => {
    const existingAcSaleData = JSON.parse(localStorage.getItem("AcSale")) || [];
    return existingAcSaleData.find(
      (item) => item.partyName === partyName && item.billDate === billDate
    );
  };

  const handleShowPopup = (item) => {
    const weights = item.modalData?.weights || [];
    const serialNumbers = weights.map(
      (_, index) => parseInt(item.modalData?.startFrom || 1) + index
    );

    const farmerObj = farmers.find(
      (farmerItem) => farmerItem.act_Name === item.actName
    );

    const farmerName = farmerObj
      ? farmerObj.act_Name + " - " + farmerObj.act_Town
      : "N/A";
    const sOnValue = farmerObj ? farmerObj.sonOf || "N/A" : "N/A";

    setSelectedItemData({
      billNo2: item.billNo2,
      billDate: item.billDate,
      partyName: item.partyName,
      farmerName: farmerName,
      // farmerName: item.farmer,
      fspno: item.fspNo,
      bondNo: item.bondNo,
      sOn: sOnValue,
      place: item.town,
      productName: item.itemName,
      gradeName: item.gradeName || "N/A",
      lotNo: item.bags,
      qualityMark: item.mark,
      bagRate: item.rate,
      grossWeight: item.weight,
      bagsWeight: item.tareWeight,
      netWeight: item.netWeight,
      amount: item.amount,
      bagAmount: item.gAmt,
      total: item.netAmt,
      weights: weights,
      serialNumbers: serialNumbers,
    });
    setShowPopup(true);
  };

  const handleClosePopup = () => setShowPopup(false);

  const formatToThreeDecimalPlaces = (value) => {
    const number = parseFloat(value);
    if (isNaN(number)) return "0.000";
    return number.toFixed(3);
  };

  const generateNextBillNo = () => {
    const existingAcSaleData = JSON.parse(localStorage.getItem("AcSale")) || [];
    const existingTransactionItems =
      JSON.parse(localStorage.getItem("transactionItems3")) || [];

    const allBillNos = [
      ...existingAcSaleData.map((item) => item.billNo2),
      ...existingTransactionItems.map((item) => item.billNo2),
    ];

    const maxBillNo = allBillNos.reduce((max, current) => {
      const match = current.match(/^ACS-(\d+)$/);
      if (match) {
        const number = parseInt(match[1], 10);
        return number > max ? number : max;
      }
      return max;
    }, 0);

    const newBillNo = `ACS-${maxBillNo + 1}`;
    setBillNo2(newBillNo);
    localStorage.setItem("billNo2", newBillNo);
    return newBillNo;
  };

  const handlePopupConfirm = () => {
    const existingAcSaleData = JSON.parse(localStorage.getItem("AcSale")) || [];
    const matchedData = existingAcSaleData.filter(
      (item) => item.partyName === partyName && item.billDate === selectedDate
    );

    const existingTransactionItems =
      JSON.parse(localStorage.getItem("transactionItems3")) || [];

    const updatedTransactionItems = [
      ...existingTransactionItems,
      ...matchedData,
    ];
    localStorage.setItem(
      "transactionItems3",
      JSON.stringify(updatedTransactionItems)
    );

    const remainingAcSaleData = existingAcSaleData.filter(
      (item) =>
        !(item.partyName === partyName && item.billDate === selectedDate)
    );
    localStorage.setItem("AcSale", JSON.stringify(remainingAcSaleData));

    setTransactionItems3(updatedTransactionItems);
    setShowAlert(false);

    if (matchedData.length > 0) {
      setBillNo2(matchedData[0].billNo2);
    }

    setButtonText("Update");
  };

  const handleAddItem = () => {
    const farmerObj = farmers.find((f) => f.act_Name === actName);

    const sOnValue = farmerObj ? farmerObj.sonOf || "N/A" : "N/A";

    const newItem = {
      itemName,
      itemId,
      gradeName: selectedGrade || "N/A",
      partyName,
      mark,
      bags,
      weight: weightInput,
      tareWeight,
      netWeight,
      rate,
      amount,
      commission,
      coolieCommission,
      gAmt,
      netAmt,
      fspNo,
      bondNo,
      dropdown,
      town,
      farmer,
      actName, // Newly Added
      sOn: sOnValue,
      currentDate: new Date().toISOString(),
      modalData,
      billNo2,
      billDate: selectedDate,
    };

    if (editIndex !== null) {
      const updatedItems = [...transactionItems3];
      updatedItems[editIndex] = {
        ...newItem,
        billNo2: updatedItems[editIndex].billNo2,
      };
      setTransactionItems3(updatedItems);
      setEditIndex(null);
    } else {
      const updatedItems = [newItem, ...transactionItems3];
      setTransactionItems3(updatedItems);
    }

    setBags("");
    setWeightInput("0.000");
    setRate("");
    setAmount("0.00");
    setCommission("0.00");
    setCoolieCommission("0.00");
    setGAmt(gAmtMultiplier.toString());
    setNetAmt("0.00");
    setFspNo("");
    setBondNo("");
    setMark("");
    setWeightEditable(false);

    setModalData({ weights: [], startFrom: 1 });
    localStorage.removeItem("acSaleModalData");
  };

  const handleSuggestionClick = (suggestion) => {
    setPartyName(suggestion.AccountName);
    setTown(suggestion.Town);
    setSuggestions([]);
    setPartyNameValid(true);

    const existingData = checkForExistingData(
      suggestion.AccountName,
      selectedDate
    );

    if (existingData) {
      setShowAlert(true);
      setBillNo2(existingData.billNo2);
    } else {
      generateNextBillNo();
    }
  };

  useEffect(() => {
    const fetchAllFarmers = async () => {
      try {
        const response = await api.get(`/Master/GetFarmers`);
        setFarmers(response.data);
      } catch (error) {
        console.error("Error fetching all farmer names:", error);
      }
    };

    fetchAllFarmers();
  }, [api]);

  const handleFarmerChange = (e) => {
    const value = e.target.value;
    setFarmer(value);

    if (value.length > 0) {
      debouncedFilterFarmers(value);
    } else {
      setFarmerSuggestions([]);
    }
  };

  const debouncedFilterFarmers = useCallback(
    debounce((query) => {
      const filteredSuggestions = farmers.filter((farmer) =>
        farmer.act_Name.toLowerCase().includes(query.toLowerCase())
      );
      setFarmerSuggestions(filteredSuggestions);
    }, 300),
    [farmers]
  );

  const handleFarmerSuggestionClick = (suggestion) => {
    setFarmer(`${suggestion.act_Name} - ${suggestion.act_Town}`);
    setactName(`${suggestion.act_Name}`);
    setFarmerSuggestions([]);
  };

  const handleModalClose = (data) => {
    if (data) {
      const validatedWeights = data.weights
        .filter((weight) => weight && !isNaN(parseFloat(weight)))
        .map((weight) => parseFloat(weight).toFixed(3));

      const serialNumbers = validatedWeights.map(
        (_, index) => data.startFrom + index
      );

      const updatedModalData = {
        weights: validatedWeights,
        serialNumbers: serialNumbers,
        startFrom: data.startFrom,
        tareWeight: data.tareWeight,
        netWeight: data.netWeight,
      };
      setModalData(updatedModalData);
      localStorage.setItem("acSaleModalData", JSON.stringify(updatedModalData));

      const totalWeight = validatedWeights.reduce(
        (acc, weight) => acc + parseFloat(weight),
        0
      );
      setWeightInput(totalWeight.toFixed(3));

      const tareWeightValue = parseFloat(data.tareWeight) || 0.0;
      const netWeightValue = parseFloat(data.netWeight) || 0.0;

      setTareWeight(tareWeightValue.toFixed(3));
      setNetWeight(netWeightValue.toFixed(3));

      localStorage.setItem("tareWeight", tareWeightValue.toFixed(3));
      localStorage.setItem("netWeight", netWeightValue.toFixed(3));

      // calculateAmount(rate, totalWeight);
      calculateAmount(rate, netWeightValue);
      setWeightEditable(false);
    } else {
      setWeightInput("0.000");
      setWeightEditable(true);
    }
    setShowModal(false);
  };

  const handleWeightChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, "");
    setWeightInput(value);
  };

  const handleRateChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, "");
    setRate(value);
    // calculateAmount(value, weightInput);
    calculateAmount(value, netWeight);
  };

  const handleBagsChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    setBags(value);
    const gAmtValue = value
      ? (parseInt(value) * gAmtMultiplier).toString()
      : gAmtMultiplier.toString();
    setGAmt(gAmtValue);
    calculateNetAmount(amount, gAmtValue);
  };

  const handleBagsBlur = () => {
    if (bags && parseInt(bags, 10) > 0) {
      setSerialCount(parseInt(bags, 10));
      setShowModal(true);
    }
  };

  const formatToTwoDecimalPlaces = (value) => {
    const number = parseFloat(value);
    if (isNaN(number)) return "";
    return number.toFixed(2);
  };

  const handleCommissionChange = (e) => {
    let value = e.target.value.replace(/[^0-9.]/g, "");

    if (/^\d*\.?\d{0,2}$/.test(value)) {
      setCommission(value);
    } else {
      setCommission(formatToTwoDecimalPlaces(value));
    }
  };

  const handleCommissionBlur = () => {
    setCommission(formatToTwoDecimalPlaces(commission));
  };

  const handleCoolieCommissionChange = (e) => {
    let value = e.target.value.replace(/[^0-9.]/g, "");

    if (/^\d*\.?\d{0,2}$/.test(value)) {
      setCoolieCommission(value);
    } else {
      setCoolieCommission(formatToTwoDecimalPlaces(value));
    }
  };

  const handleCoolieCommissionBlur = () => {
    setCoolieCommission(formatToTwoDecimalPlaces(coolieCommission));
  };

  const calculateAmount = (rate, weight) => {
    const rateValue = parseFloat(rate) || 0;
    const weightValue = parseFloat(weight) || 0;
    const amountValue = ((rateValue * weightValue) / 100).toFixed(2);
    setAmount(amountValue);
    calculateNetAmount(amountValue, gAmt);
  };

  const calculateNetAmount = (amountValue, gAmtValue) => {
    const netAmount = (parseFloat(amountValue) + parseFloat(gAmtValue)).toFixed(
      2
    );
    setNetAmt(netAmount);
  };

  const isFormValid = () => {
    return (
      partyName.trim() &&
      itemName.trim() &&
      mark.trim() &&
      bags.trim() &&
      rate.trim() &&
      partyNameValid &&
      itemNameValid
    );
  };

  const resetFields = () => {
    setItemName("");
    setPartyName("");
    setMark("");
    setTown("");
    setBags("");
    setWeightInput("0.000");
    setRate("");
    setAmount("0.00");
    setCommission("0.00");
    setCoolieCommission("0.00");
    setGAmt(gAmtMultiplier.toString());
    setNetAmt("0.00");
    setFspNo("");
    setBondNo("");
    setSerialCount(0);
    setDropdown(transactionTypes.length > 0 ? transactionTypes[0].ttId : "");
    setFarmer("");
    setactName("");
    setItemNameValid(true);
    setPartyNameValid(true);
    setSelectedGrade("");
    setGradeOptions([]);
    setItemId("");
    setModalData({});
    setWeightEditable(false);
    setSelectedDate(billDate);
  };

  const handleEdit = (index) => {
    const item = transactionItems3[index];
    setItemName(item.itemName || "");
    setItemId(item.itemId || "");
    setSelectedGrade(item.gradeName || "");
    setPartyName(item.partyName || "");
    setMark(item.mark || "");
    setBags(item.bags || "");
    setWeightInput(item.weight || "0.000");
    setTareWeight(item.tareWeight || "0.00");
    setNetWeight(item.netWeight || "0.00");
    setRate(item.rate || "");
    setAmount(item.amount || "0.00");
    setCommission(item.commission || "0.00");
    setCoolieCommission(item.coolieCommission || "0.00");
    setGAmt(item.gAmt || gAmtMultiplier.toString());
    setNetAmt(item.netAmt || "0.00");
    setFspNo(item.fspNo || "");
    setBondNo(item.bondNo || "");
    setSerialCount(item.bags || 0);
    setDropdown(item.dropdown || transactionTypes[0]?.ttId || "");
    setTown(item.town || "");
    setFarmer(item.farmer || "");
    setactName(item.actName || "");
    setModalData(item.modalData || {});
    setEditIndex(index);
    setWeightEditable(item.modalData.weights.length === 0);

    setBillNo2(item.billNo2);
    localStorage.setItem("billNo2", item.billNo2);
  };

  const handleDelete = (index) => {
    const updatedItems = transactionItems3.filter((_, i) => i !== index);
    setTransactionItems3(updatedItems);
  };

  const handlePartyNameChange = (e) => {
    const value = e.target.value;
    setPartyName(value);

    if (value.length >= 3) {
      fetchPartyNameSuggestions(value);
    } else {
      setSuggestions([]);
    }
  };

  const handleItemNameChange = (e) => {
    const value = e.target.value;
    setItemName(value);

    fetchItemNameSuggestions(value);

    const filteredSuggestions = itemSuggestions.filter((suggestion) =>
      suggestion.itemName.toLowerCase().includes(value.toLowerCase())
    );

    const matchesSuggestion = filteredSuggestions.some(
      (suggestion) => suggestion.itemName === value
    );

    if (matchesSuggestion) {
      const matchedItem = filteredSuggestions.find(
        (suggestion) => suggestion.itemName === value
      );
      setGAmt(matchedItem.gAmt);
      setItemId(matchedItem.itemId);
    } else {
      setGAmt("0");
      setItemId("");
    }

    setItemNameValid(matchesSuggestion || value.length === 0);
  };

  const handleItemNameFocus = () => {
    fetchItemNameSuggestions("");
  };

  const handlePartyNameBlur = () => {
    if (suggestions.length > 0) {
      const isPartyValid = suggestions.some(
        (suggestion) => suggestion.AccountName === partyName
      );
      setPartyNameValid(isPartyValid || partyName.length === 0);
    } else {
      setPartyNameValid(true);
    }
  };

  const handleItemNameBlur = () => {
    setItemNameValid(
      itemSuggestions.some((suggestion) => suggestion.itemName === itemName) ||
        itemName.length === 0
    );
  };

  const fetchItemNameSuggestions = async (query) => {
    try {
      const response = await api.get(`/Master/ListofItems`, {
        params: { itemName: query },
      });
      const mappedSuggestions = response.data.map((suggestion) => ({
        itemName: suggestion.itemName,
        gAmt: suggestion.gunnyAmt.toString(),
        itemId: suggestion.itemId || suggestion.itemid || suggestion.id,
      }));
      setItemSuggestions(mappedSuggestions);

      if (mappedSuggestions.length > 0) {
        setGAmt(mappedSuggestions[0].gAmt);
        setItemId(mappedSuggestions[0].itemId);
      } else {
        setGAmt("0");
        setItemId("");
      }
    } catch (error) {
      console.error("Error fetching item name suggestions", error);
      setItemSuggestions([]);
    }
  };

  const fetchPartyNameSuggestions = async (query) => {
    try {
      const response = await api.get(`/Master/Accounts`, {
        params: { AccountName: query },
      });
      const mappedSuggestions = response.data.map((suggestion) => ({
        AccountName: suggestion.act_Name,
        Town: suggestion.act_Town,
      }));
      setSuggestions(mappedSuggestions);
    } catch (error) {
      console.error("Error fetching party name suggestions", error);
      setSuggestions([]);
    }
  };

  const fetchCommissionAndCoolie = async () => {
    try {
      const response = await api.get("/Login/GetCommissionAndCooli");
      if (response.data && response.data.length > 0) {
        const { commission, cooli } = response.data[0];
        setCommission(commission.toFixed(2));
        setCoolieCommission(cooli.toFixed(2));
      }
    } catch (error) {
      console.error("Error fetching commission and coolie commission", error);
      setCommission("0.00");
      setCoolieCommission("0.00");
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSaveAndUpdate = () => {
    if (buttonText === "Update") {
      const existingAcSaleData =
        JSON.parse(localStorage.getItem("AcSale")) || [];
      const updatedAcSaleData = [...existingAcSaleData, ...transactionItems3];
      localStorage.setItem("AcSale", JSON.stringify(updatedAcSaleData));
      localStorage.removeItem("transactionItems3");
      setTransactionItems3([]);
      setButtonText("Save & Clear");

      generateNextBillNo();

      resetFields();
    } else {
      handleSaveAndNew();
    }
  };

  const handleSaveAndNew = async () => {
    const currentDate = new Date().toISOString();
    const itemsWithDate = transactionItems3.map((item) => ({
      ...item,
      currentDate,
    }));

    try {
      const existingAcSaleData =
        JSON.parse(localStorage.getItem("AcSale")) || [];

      const updatedAcSaleData = [...existingAcSaleData, ...itemsWithDate];

      localStorage.setItem("AcSale", JSON.stringify(updatedAcSaleData));

      setTransactionItems3([]);

      resetFields();

      localStorage.removeItem("acSaleModalData");

      generateNextBillNo();
    } catch (error) {
      console.error("Failed to save transaction data", error);
    }
  };

  useEffect(() => {
    if (itemId) {
      fetchGradeOptions(itemId);
    } else {
      setGradeOptions([]);
      setSelectedGrade("");
    }
  }, [itemId]);

  const fetchGradeOptions = async (itemId) => {
    try {
      const response = await api.get(`/Master/GetCommodities`, {
        params: { itemid: itemId },
      });

      if (Array.isArray(response.data)) {
        const grades = response.data.map((grade) => ({
          gradeId: grade.cmdTypeID,
          gradeName: grade.cmdDesc,
        }));
        setGradeOptions(grades);
      } else {
        console.error("Unexpected API response structure:", response.data);
        setGradeOptions([]);
      }
    } catch (error) {
      console.error("Error fetching grade options:", error);
      setGradeOptions([]);
    }
  };

  useEffect(() => {
    const fetchGAmtMultiplier = async () => {
      try {
        const response = await api.get("/api/g-amt-multiplier");
        setGAmtMultiplier(response.data.multiplier);
        // setGAmtMultiplier(0);
      } catch (error) {
        console.error("Error fetching G Amt Multiplier", error);
      }
    };

    const fetchTransactionTypes = async () => {
      try {
        const response = await api.get("/Master/Ttypes");
        if (Array.isArray(response.data)) {
          setTransactionTypes(response.data);
          setDropdown(response.data.length > 0 ? response.data[0].ttId : "");
        } else {
          console.error("Unexpected response structure", response.data);
          setTransactionTypes([]);
        }
      } catch (error) {
        console.error("Error fetching transaction types", error);
        setTransactionTypes([]);
      } finally {
        setLoading(false);
      }
    };

    fetchGAmtMultiplier();
    fetchTransactionTypes();
    fetchCommissionAndCoolie();

    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }

    const savedCharges = localStorage.getItem("charges3");
    if (savedCharges) {
      setCharges3(JSON.parse(savedCharges));
    }

    const savedItems = localStorage.getItem("transactionItems3");
    if (savedItems) {
      setTransactionItems3(JSON.parse(savedItems));
    }

    fetchItemNameSuggestions("");
  }, [navigate, api]);

  useEffect(() => {
    localStorage.setItem(
      "transactionItems3",
      JSON.stringify(transactionItems3)
    );
    localStorage.setItem("billNo2", billNo2);
    localStorage.setItem("selectedDate", selectedDate);
  }, [transactionItems3, billNo2, selectedDate]);

  const filteredItems = transactionItems3.filter((item) =>
    item.farmer.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const groupedItems = filteredItems.reduce((groups, item) => {
    const key = `${item.itemName}-${item.farmer}-${item.mark}`;
    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(item);
    return groups;
  }, {});

  const handleJsonDownload = async (item) => {
    if (!Capacitor.isNativePlatform()) {
      const json = JSON.stringify(item, null, 2);
      const blob = new Blob([json], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${item.billNo || item.billNo2 || "data"}.json`;
      a.click();
      URL.revokeObjectURL(url);
      return;
    }

    try {
      const json = JSON.stringify(item, null, 2);
      const fileName = `${item.billNo || item.billNo2 || "data"}.json`;

      const result = await Filesystem.writeFile({
        path: fileName,
        data: json,
        directory: Directory.Documents,
        encoding: Encoding.UTF8,
      });

      if (isCordovaPrinterAvailable()) {
        window.cordova.plugins.printer.print(
          `<pre>${json}</pre>`,
          "Print JSON",
          function (res) {
            if (res) {
              console.log("Print Successful");
            } else {
              console.error("Print Failed");
              alert("Failed to print the document.");
            }
          }
        );
      } else {
        console.error("Cordova Printer Plugin not available.");
        alert("Printing is not supported on this device.");
      }
    } catch (error) {
      console.error("Error downloading JSON:", error);
      alert("Failed to download JSON file");
    }
  };

  const handleExcelDownload = async () => {
    if (!Capacitor.isNativePlatform()) {
      try {
        const { utils, write } = await import("xlsx");

        let dataToDownload = transactionItems3;

        const worksheet = utils.json_to_sheet(dataToDownload);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "AcSale");

        const excelBuffer = write(workbook, {
          bookType: "xlsx",
          type: "array",
        });

        const blob = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `AcSale-${selectedDate}.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading Excel on web:", error);
        alert("Failed to download Excel file");
      }
      return;
    }

    try {
      const { utils, write } = await import("xlsx");

      let dataToDownload = transactionItems3;

      const worksheet = utils.json_to_sheet(dataToDownload);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "AcSale");

      const excelBuffer = write(workbook, { bookType: "xlsx", type: "array" });

      const base64Data = Buffer.from(excelBuffer).toString("base64");

      const fileName = `AcSale-${selectedDate}.xlsx`;

      const result = await Filesystem.writeFile({
        path: fileName,
        data: base64Data,
        directory: Directory.Documents,
        encoding: Encoding.UTF8,
      });

      const excelContent = "Excel file content cannot be printed directly.";
      if (isCordovaPrinterAvailable()) {
        window.cordova.plugins.printer.print(
          excelContent,
          "Print Excel",
          function (res) {
            if (res) {
              console.log("Print Successful");
            } else {
              console.error("Print Failed");
              alert("Failed to print the document.");
            }
          }
        );
      } else {
        console.error("Cordova Printer Plugin not available.");
        alert("Printing is not supported on this device.");
      }
    } catch (error) {
      console.error("Error downloading Excel:", error);
      alert("Failed to download Excel file");
    }
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const convertDateToISO = (date) => {
    const [dd, mm, yyyy] = date.split("-");
    const day = dd.padStart(2, "0");
    const month = mm.padStart(2, "0");
    return `${yyyy}-${month}-${day}`;
  };

  const filteredPurchaseList = [];
  const filteredPlatformSaleList = [];
  const filteredAcSaleList = transactionItems3.filter(
    (item) => convertDateToISO(item.billDate) === convertDateToISO(selectedDate)
  );

  return (
    <>
      <header className="main-header">
        <NavLink to="/">
          <img src={logo} alt="Kliq Soft Logo" className="logo" />
        </NavLink>
      </header>
      <main>
        <div className="transactions">
          <p>Transactions</p>
          <p>
            <img src={sale} alt="Ac Sale Icon" />
            AcSale
          </p>
        </div>

        <div className="goback">
          <NavLink to="/">
            <svg
              fill="#185492"
              className="left-arrow"
              xmlns="http://www.w3.org/2000/svg"
              width={22}
              height={22}
              viewBox="0 0 20 20"
            >
              <path d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm6,11H7.38l2.376,2.375L8.342,14.789,3.607,10.055,8.342,5.322,9.756,6.736,7.49,9H16Z" />
            </svg>
          </NavLink>
        </div>

        <div className="form-container clear">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <form className="form form1">
              <div className="input-box active-grey focus">
                <label className="input-label">Dropdown/select list</label>
                <select
                  className="input-1"
                  value={dropdown}
                  ref={dropdownRef}
                  onChange={(e) => setDropdown(e.target.value)}
                >
                  {Array.isArray(transactionTypes) &&
                  transactionTypes.length > 0 ? (
                    transactionTypes.map((type) => (
                      <option key={type.ttId} value={type.ttId}>
                        {type.ttName}
                      </option>
                    ))
                  ) : (
                    <option>No options available</option>
                  )}
                </select>
              </div>

              <div className="row row-bill-info">
                <div className="input-box col focus">
                  <label className="input-label">Bill No.</label>
                  <input
                    type="text"
                    className="input-1"
                    value={billNo2}
                    readOnly
                  />
                </div>
                <div className="input-box col focus">
                  <label className="input-label">Bill Date</label>
                  <input
                    type="text"
                    className="input-1"
                    value={billDate}
                    readOnly
                  />
                </div>
              </div>

              <div className="row">
                <div className="input-box col focus">
                  <label
                    className={`input-label ${
                      !partyNameValid ? "label-invalid" : ""
                    }`}
                  >
                    Party Name *
                  </label>
                  <input
                    type="text"
                    id="inputField"
                    className={`input-1 ${
                      !partyNameValid ? "input-invalid" : ""
                    }`}
                    ref={partyNameRef}
                    required
                    value={partyName || ""}
                    onChange={handlePartyNameChange}
                    onBlur={handlePartyNameBlur}
                  />
                  {suggestions.length > 0 && (
                    <ul className="suggestions-list">
                      {suggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          onClick={() => handleSuggestionClick(suggestion)}
                        >
                          {suggestion.AccountName}
                        </li>
                      ))}
                    </ul>
                  )}
                  {!partyNameValid && (
                    <div className="invalid-feedback">
                      Party name does not exist.{" "}
                      <NavLink to="/accountpage" className="add-new">
                        Add New Account
                      </NavLink>
                    </div>
                  )}
                </div>
                <div className="input-box col focus">
                  <label className="input-label">Town</label>
                  <input
                    type="text"
                    className="input-1"
                    ref={townRef}
                    value={town || ""}
                    onChange={(e) => setTown(e.target.value)}
                  />
                </div>
              </div>
              <div className="input-box focus">
                <label className="input-label">Item Name *</label>
                <select
                  className={`input-1 ${!itemNameValid ? "input-invalid" : ""}`}
                  ref={itemNameRef}
                  value={itemName || ""}
                  onChange={handleItemNameChange}
                  onFocus={handleItemNameFocus}
                  onBlur={handleItemNameBlur}
                >
                  <option value="">Select an item</option>
                  {itemSuggestions.map((suggestion, index) => (
                    <option
                      key={suggestion.itemId || index}
                      value={suggestion.itemName}
                    >
                      {suggestion.itemName}
                    </option>
                  ))}
                </select>
                {!itemNameValid && (
                  <div className="invalid-feedback">
                    Item name does not exist.
                  </div>
                )}
              </div>

              <div className="input-box focus">
                <label className="input-label">Grade Name</label>
                <select
                  className="input-1"
                  value={selectedGrade}
                  onChange={(e) => setSelectedGrade(e.target.value)}
                >
                  <option value="">Select Grade</option>
                  {gradeOptions.map((grade) => (
                    <option key={grade.gradeId} value={grade.gradeName}>
                      {grade.gradeName}
                    </option>
                  ))}
                </select>
              </div>
            </form>
          )}

          <form className="form form2">
            <div className="row row1">
              <div className="input-box col col1 focus">
                <label className="input-label">Farmer Name</label>
                <input
                  type="text"
                  className="input-1"
                  value={farmer}
                  onChange={handleFarmerChange}
                  placeholder="Start typing a farmer name"
                />
                {farmerSuggestions.length > 0 && (
                  <ul className="suggestions-list">
                    {farmerSuggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        onClick={() => handleFarmerSuggestionClick(suggestion)}
                      >
                        {suggestion.act_Name} - {suggestion.act_Town}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="input-box col col2 focus">
                <label className="input-label">Mark *</label>
                <input
                  type="text"
                  className="input-1"
                  ref={markRef}
                  value={mark || ""}
                  onChange={(e) => setMark(e.target.value)}
                />
              </div>
            </div>
            <div className="row row2">
              <div className="input-box col col1 focus">
                <label className="input-label">Bags *</label>
                <input
                  type="text"
                  className="input-1"
                  ref={bagsRef}
                  value={bags || ""}
                  onChange={handleBagsChange}
                  inputMode="numeric"
                  onBlur={handleBagsBlur}
                />
              </div>
              <div className="input-box col col3 focus">
                <label className="input-label">Weight</label>
                <input
                  type="text"
                  className="input-1 numeric-input three-decimal"
                  ref={weightRef}
                  inputMode="numeric"
                  value={weightInput}
                  onInput={handleWeightChange}
                  onBlur={() =>
                    setWeightInput(formatToThreeDecimalPlaces(weightInput))
                  }
                  readOnly={!weightEditable}
                />
              </div>
            </div>
            <div className="row row3">
              <div className="input-box col col1 focus">
                <label className="input-label">Rate *</label>
                <input
                  type="text"
                  className="input-1 numeric-input"
                  ref={rateRef}
                  value={rate || ""}
                  inputMode="numeric"
                  onChange={handleRateChange}
                />
              </div>
              <div className="input-box col col2 focus">
                <label className="input-label">Amount</label>
                <input
                  type="text"
                  className="input-1 numeric-input"
                  value={amount}
                  readOnly
                />
              </div>
              <div className="input-box col col3 focus">
                <label className="input-label">Commission</label>
                <input
                  type="text"
                  className="input-1 numeric-input"
                  value={commission}
                  onChange={handleCommissionChange}
                  onBlur={handleCommissionBlur}
                />
              </div>
            </div>
            <div className="row row4">
              <div className="input-box col col1 focus">
                <label className="input-label">Coolie Commission</label>
                <input
                  type="text"
                  className="input-1 numeric-input"
                  value={coolieCommission}
                  onChange={handleCoolieCommissionChange}
                  onBlur={handleCoolieCommissionBlur}
                />
              </div>
              <div className="input-box col col2 focus">
                <label className="input-label">G.Amt</label>
                <input
                  type="text"
                  className="input-1 numeric-input"
                  value={gAmt}
                  readOnly
                />
              </div>
              <div className="input-box col col3 focus">
                <label className="input-label">Net Amt</label>
                <input
                  type="text"
                  className="input-1 numeric-input"
                  value={netAmt}
                  readOnly
                />
              </div>
            </div>
            <div className="row row5">
              <div className="input-box col col1 focus">
                <label className="input-label">FSP No.</label>
                <input
                  type="text"
                  className="input-1 numeric-input"
                  value={fspNo}
                  onChange={(e) => setFspNo(e.target.value)}
                />
              </div>

              <div className="input-box col col1 focus">
                <label className="input-label">Bond No.</label>
                <input
                  type="text"
                  className="input-1 numeric-input"
                  value={bondNo}
                  onChange={(e) => setBondNo(e.target.value)}
                />
              </div>
            </div>
            <button
              type="button"
              id="submitButton"
              className={`btn btn-primary pull-right ${
                !isFormValid() ? "btn-disabled" : ""
              }`}
              disabled={!isFormValid()}
              onClick={handleAddItem}
            >
              {editIndex !== null ? "Update Item" : "Add Item"}
            </button>
            <div className="listAndSave">
              <button
                type="button"
                className="btn btn-save"
                onClick={handleSaveAndUpdate}
              >
                {buttonText}
              </button>
            </div>
          </form>
        </div>

        <div
          className="item-search"
          style={{ marginBottom: filteredItems.length > 0 ? "0" : "3rem" }}
        >
          <input
            type="text"
            name="search"
            id="search"
            placeholder="Farmer Name"
            value={searchQuery || ""}
            onChange={handleSearchChange}
          />
          <button className="btn btn-search">View</button>
        </div>

        {filteredItems.length > 0 && (
          <div
            className="item-list"
            style={{ overflowX: "auto", marginBottom: "3.5rem" }}
          >
            <table>
              <thead>
                <tr>
                  <th scope="col">Bags</th>
                  <th scope="col">Weight</th>
                  <th scope="col">Rate</th>
                  <th scope="col">G.Amt</th>
                  <th scope="col">Net Amt</th>
                  <th scope="col">FSP No.</th>
                  <th scope="col">Bond No.</th>
                  <th
                    scope="col"
                    className="action"
                    colSpan={3}
                    style={{ opacity: 0 }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(groupedItems).map(
                  ([key, items], groupIndex) => (
                    <React.Fragment key={`group-${groupIndex}`}>
                      <tr className="trHeader">
                        <td colSpan={8}>
                          {items[0].itemName} - {items[0].farmer}
                        </td>
                        <td colSpan={5} className="mark">
                          {items[0].mark}
                        </td>
                      </tr>
                      {items.map((item, index) => (
                        <tr key={`data-${groupIndex}-${index}`}>
                          <td>{item.bags}</td>
                          <td>{item.weight}</td>
                          <td>{item.rate}</td>
                          <td>{item.gAmt}</td>
                          <td>{item.netAmt}</td>
                          <td>{item.fspNo}</td>
                          <td>{item.bondNo}</td>
                          <td>
                            <img
                              id="itemImg"
                              src={preview}
                              alt="Preview Download"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleShowPopup(item)}
                            />
                          </td>
                          <td>
                            <img
                              src={editIcon}
                              alt="Edit"
                              onClick={() => handleEdit(index)}
                              style={{ cursor: "pointer" }}
                            />
                          </td>
                          <td>
                            <img
                              src={trashIcon}
                              alt="Delete"
                              onClick={() => handleDelete(index)}
                              style={{ cursor: "pointer" }}
                            />
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  )
                )}
              </tbody>
              <tfoot>
                <tr>
                  <th colSpan={10}>No. of Items: {transactionItems3.length}</th>
                </tr>
              </tfoot>
            </table>
          </div>
        )}
      </main>
      {showModal && (
        <ModalWindow
          serialCount={serialCount}
          onClose={handleModalClose}
          initialWeights={modalData.weights}
          initialSerials={modalData.serialNumbers}
          startSerial={modalData.startFrom}
        />
      )}
      {chargeModalOpen && (
        <ChargeModal
          isOpen={chargeModalOpen}
          onClose={() => setChargeModalOpen(false)}
          chargesData={charges3}
          onSave={(data) => setCharges3(data)}
        />
      )}
      {showAlert && (
        <>
          <div
            className="alert-popup-overlay"
            onClick={() => setShowAlert(false)}
          ></div>
          <div className="alert-popup">
            <p>Party Name already exists on this day.</p>
            <button onClick={handlePopupConfirm}>OK</button>
          </div>
        </>
      )}
      {showPopup && (
        <WeightListPopup
          onClose={handleClosePopup}
          itemData={selectedItemData}
        />
      )}
    </>
  );
}

export default AcSale;

// src/components/AcSale.jsx

// import React, {
//   useState,
//   useEffect,
//   useRef,
//   useCallback,
//   useMemo,
// } from "react";
// import { NavLink, useNavigate } from "react-router-dom";
// import axios from "axios";
// import debounce from "lodash.debounce";
// import "./styles/Form.css";
// import "./styles/ItemsList.css";
// import "./styles/AccountAndTransactions.css";
// import "./styles/Popup.css";
// import logo from "../images/Logo.jpg";
// import preview from "../images/preview.svg";
// import editIcon from "../images/edit.svg";
// import trashIcon from "../images/trash.svg";
// import sale from "../images/sale.svg";
// import ModalWindow from "./ModalWindow";
// import ChargeModal from "./ChargeModal";
// import { Buffer } from "buffer";
// import { Capacitor } from "@capacitor/core";
// import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";

// // Polyfill for Buffer
// window.Buffer = Buffer;

// // Utility function to check if Cordova Printer Plugin is available
// const isCordovaPrinterAvailable = () => {
//   return (
//     window.cordova &&
//     window.cordova.plugins &&
//     window.cordova.plugins.printer &&
//     typeof window.cordova.plugins.printer.print === "function"
//   );
// };

// // Styles for the WeightListPopup
// const styles = {
//   overlay: {
//     position: "fixed",
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     backgroundColor: "rgba(0,0,0,0.5)",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     zIndex: 1000,
//   },
//   popup: {
//     backgroundColor: "#fff",
//     padding: "20px",
//     borderRadius: "8px",
//     width: "80%",
//     maxHeight: "90%",
//     overflowY: "auto",
//     position: "relative",
//     boxShadow: "0 5px 15px rgba(0,0,0,0.3)",
//   },
//   closeButton: {
//     position: "absolute",
//     top: "10px",
//     right: "15px",
//     background: "transparent",
//     border: "none",
//     fontSize: "24px",
//     cursor: "pointer",
//   },
//   printButton: {
//     background: "#185492",
//     color: "#fff",
//     fontSize: "14px",
//     border: "none",
//     padding: "10px 15px",
//     cursor: "pointer",
//     boxShadow: "0px 5px 15px rgba(0,0,0,0.3)",
//     marginBottom: "20px",
//   },
//   contentContainer: {
//     textAlign: "left",
//   },
//   header: {
//     textAlign: "center",
//     marginBottom: "20px",
//   },
//   table: {
//     borderTop: "2px dashed #202020",
//     borderBottom: "2px dashed #202020",
//     borderCollapse: "collapse",
//     width: "100%",
//     marginTop: "10px",
//     marginBottom: "10px",
//   },
//   tbody: {
//     borderTop: "2px dashed #202020",
//     borderBottom: "2px dashed #202020",
//     textAlign: "center",
//   },

//   netWeightContainer: {
//     textAlign: "right",
//     marginTop: "10px",
//     fontWeight: "bold",
//     paddingRight: "20px", // Adjust as needed for alignment
//   },
// };

// // Internal WeightListPopup Component
// const WeightListPopup = ({ onClose, itemData }) => {
//   const handlePrint = () => {
//     const printContent = document.getElementById(
//       "weight-list-popup-content"
//     ).innerHTML;

//     if (Capacitor.isNativePlatform()) {
//       if (isCordovaPrinterAvailable()) {
//         window.cordova.plugins.printer.print(
//           printContent,
//           "Print Document",
//           function (res) {
//             if (res) {
//               console.log("Print Successful");
//             } else {
//               console.error("Print Failed");
//               alert("Failed to print the document.");
//             }
//           }
//         );
//       } else {
//         console.error("Cordova Printer Plugin not available.");
//         alert("Printing is not supported on this device.");
//       }
//     } else {
//       let iframeDoc = document.createElement("iframe");
//       iframeDoc.style.position = "fixed";
//       iframeDoc.style.right = "0";
//       iframeDoc.style.bottom = "0";
//       iframeDoc.style.width = "0";
//       iframeDoc.style.height = "0";
//       iframeDoc.style.border = "0";
//       document.body.appendChild(iframeDoc);

//       let iframeDocument = iframeDoc.contentWindow || iframeDoc.contentDocument;
//       if (iframeDocument.document) iframeDocument = iframeDocument.document;

//       iframeDocument.open();
//       iframeDocument.write(`
//         <html>
//           <head>
//             <title>Print Document</title>
//             <style>
//               body { font-family: Arial, sans-serif; padding: 20px; }
//               table { width: 100%; border-collapse: collapse; }
//               th, td { border: 1px solid #000; padding: 8px; text-align: left; }
//               th { background-color: #f2f2f2; }
//             </style>
//           </head>
//           <body>
//             ${printContent}
//           </body>
//         </html>
//       `);
//       iframeDocument.close();

//       iframeDoc.onload = () => {
//         setTimeout(() => {
//           iframeDoc.contentWindow.focus();
//           iframeDoc.contentWindow.print();
//         }, 500);
//       };
//     }
//   };

//   if (!itemData) return null;

//   return (
//     <div id="popup-overlay" style={styles.overlay}>
//       <div className="weight-list-popup" style={styles.popup}>
//         <button
//           className="close-button"
//           onClick={onClose}
//           style={styles.closeButton}
//         >
//           &times;
//         </button>
//         <button
//           className="print-button"
//           onClick={handlePrint}
//           style={styles.printButton}
//         >
//           Print
//         </button>
//         <div
//           className="weight_list_container"
//           id="weight-list-popup-content"
//           style={styles.contentContainer}
//         >
//           <h2 style={styles.header}>AC Sale</h2>
//           <p>
//             <strong>Bill No:</strong> {itemData.billNo2}
//           </p>
//           <p>
//             <strong>Bill Date:</strong> {itemData.billDate}
//           </p>
//           <p>
//             <strong>Bond No:</strong> {itemData.bondNo}
//           </p>
//           <p>
//             <strong>FSP:</strong> {itemData.fspno}
//           </p>
//           <p>
//             <strong>Party Name:</strong> {itemData.partyName} / {itemData.place}
//           </p>
//           <p>
//             <strong>Farmer Name:</strong> {itemData.farmerName}
//           </p>
//           <p>
//             <strong>S/o:</strong> {itemData.sOn}
//           </p>
//           <p>
//             <strong>Place:</strong> {itemData.place}
//           </p>
//           <p>
//             <strong>Product Name:</strong> {itemData.productName}
//           </p>
//           <p>
//             <strong>Grade Name:</strong> {itemData.gradeName}
//           </p>
//           <p>
//             <strong>LOT NO:</strong> {itemData.lotNo}
//           </p>
//           <p>
//             <strong>Quality Mark:</strong> {itemData.qualityMark}
//           </p>
//           <p>
//             <strong>Rate:</strong> {itemData.bagRate}
//           </p>
//           <p>
//             <strong>Gross Weight:</strong> {itemData.grossWeight}
//           </p>
//           <p>
//             <strong>Tare Weight:</strong> {itemData.bagsWeight}
//           </p>
//           <p>
//             <strong>Net Weight:</strong> {itemData.netWeight}
//           </p>
//           <br />
//           <p>
//             <strong>Amount:</strong> Rs. {itemData.amount}
//           </p>
//           <table style={styles.table}>
//             <thead>
//               <tr>
//                 <th scope="col">S.NO</th>
//                 <th scope="col">Quantity(Kg)</th>
//               </tr>
//             </thead>
//             <tbody style={styles.tbody}>
//               {itemData.serialNumbers.map((serial, index) => (
//                 <tr key={index}>
//                   <td scope="row">{serial}</td>
//                   <td>{itemData.weights[index]}</td>
//                 </tr>
//               ))}
//             </tbody>
//             {/* <tfoot>
//               <tr>
//                 <th scope="row">Net Wgt:</th>
//                 <th scope="row" className="total-Qty">
//                   {itemData.netWeight}
//                 </th>
//               </tr>
//             </tfoot> */}
//           </table>
//           <div style={styles.netWeightContainer}>
//             <strong>Net Wgt:</strong> {itemData.netWeight}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// function AcSale() {
//   const [showPopup, setShowPopup] = useState(false);
//   const [selectedItemData, setSelectedItemData] = useState(null);
//   const [showAlert, setShowAlert] = useState(false);
//   const [farmers, setFarmers] = useState([]);
//   const [farmerSuggestions, setFarmerSuggestions] = useState([]);
//   const [billNo2, setBillNo2] = useState(
//     localStorage.getItem("billNo2") || "ACS-1"
//   );
//   const [billDate, setBillDate] = useState(() => {
//     const currentDate = new Date();
//     return `${String(currentDate.getDate()).padStart(2, "0")}-${String(
//       currentDate.getMonth() + 1
//     ).padStart(2, "0")}-${currentDate.getFullYear()}`;
//   });
//   const [selectedDate, setSelectedDate] = useState(
//     localStorage.getItem("selectedDate") || billDate
//   );
//   const [partyName, setPartyName] = useState("");
//   const [showModal, setShowModal] = useState(false);
//   const [serialCount, setSerialCount] = useState(0);
//   const [weightInput, setWeightInput] = useState("0.000");
//   const [tareWeight, setTareWeight] = useState(
//     localStorage.getItem("tareWeight") || "0.00"
//   );
//   const [netWeight, setNetWeight] = useState(
//     localStorage.getItem("netWeight") || "0.00"
//   );
//   const [bags, setBags] = useState("");
//   const [rate, setRate] = useState("");
//   const [amount, setAmount] = useState("0.00");
//   const [coolieCommission, setCoolieCommission] = useState("0.00");
//   const [commission, setCommission] = useState("0.00");
//   const [gAmt, setGAmt] = useState("0");
//   const [netAmt, setNetAmt] = useState("0.00");
//   const [fspNo, setFspNo] = useState("");
//   const [bondNo, setBondNo] = useState("");
//   const [transactionItems3, setTransactionItems3] = useState([]);
//   const [itemName, setItemName] = useState("");
//   const [itemSuggestions, setItemSuggestions] = useState([]);
//   const [mark, setMark] = useState("");
//   const [dropdown, setDropdown] = useState("");
//   const [town, setTown] = useState("");
//   const [farmer, setFarmer] = useState("");
//   const [actName, setactName] = useState("");
//   const [editIndex, setEditIndex] = useState(null);
//   const [searchQuery, setSearchQuery] = useState("");
//   const [suggestions, setSuggestions] = useState([]);
//   const [partyNameValid, setPartyNameValid] = useState(true);
//   const [itemNameValid, setItemNameValid] = useState(true);
//   const [gAmtMultiplier, setGAmtMultiplier] = useState(0);
//   const [transactionTypes, setTransactionTypes] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [chargeModalOpen, setChargeModalOpen] = useState(false);
//   const [charges3, setCharges3] = useState([
//     {
//       id: 1,
//       chargeName: "TCS P",
//       rate: "0.100",
//       value: "0.000",
//       adjAcName: "TCS Purchase A/C",
//       gst: "0",
//       gstAmt: "0.00",
//       total: "0.000",
//     },
//     {
//       id: 2,
//       chargeName: "Round off",
//       rate: "1.000",
//       value: "0.000",
//       adjAcName: "ROUND OFF",
//       gst: "0",
//       gstAmt: "0.00",
//       total: "0.000",
//     },
//   ]);
//   const [buttonText, setButtonText] = useState("Save & New");
//   const [weightEditable, setWeightEditable] = useState(false);

//   const [gradeOptions, setGradeOptions] = useState([]);
//   const [selectedGrade, setSelectedGrade] = useState("");
//   const [itemId, setItemId] = useState("");

//   const [modalData, setModalData] = useState(
//     JSON.parse(localStorage.getItem("acSaleModalData")) || {
//       weights: [],
//       serialNumbers: [],
//       startFrom: 1,
//       tareWeight: "0.00",
//       netWeight: "0.00",
//     }
//   );

//   const navigate = useNavigate();
//   const partyNameRef = useRef(null);
//   const itemNameRef = useRef(null);
//   const markRef = useRef(null);
//   const bagsRef = useRef(null);
//   const weightRef = useRef(null);
//   const rateRef = useRef(null);
//   const dropdownRef = useRef(null);
//   const townRef = useRef(null);
//   const farmerRef = useRef(null);
//   const dateRef = useRef(null);

//   const selectedCompany = localStorage.getItem("selectedCompany") || "";

//   const api = useMemo(() => {
//     const baseURL =
//       process.env.REACT_APP_API_BASE_URL || "https://Kliqss.in/CSAPI";
//     const instance = axios.create({
//       baseURL: baseURL,
//     });

//     instance.interceptors.request.use(
//       (config) => {
//         const token = localStorage.getItem("token");
//         if (token) {
//           config.headers["Authorization"] = `Bearer ${token}`;
//         }
//         return config;
//       },
//       (error) => Promise.reject(error)
//     );

//     instance.interceptors.response.use(
//       (response) => response,
//       (error) => {
//         console.error("API Response Error:", error);
//         return Promise.reject(error);
//       }
//     );

//     return instance;
//   }, []);

//   const checkForExistingData = (partyName, billDate) => {
//     const existingAcSaleData = JSON.parse(localStorage.getItem("AcSale")) || [];
//     return existingAcSaleData.find(
//       (item) => item.partyName === partyName && item.billDate === billDate
//     );
//   };

//   const handleShowPopup = (item) => {
//     const weights = item.modalData?.weights || [];
//     const serialNumbers = weights.map(
//       (_, index) => parseInt(item.modalData?.startFrom || 1) + index
//     );

//     const farmerObj = farmers.find(
//       (farmerItem) => farmerItem.act_Name === item.actName
//     );

//     const farmerName = farmerObj
//       ? `${farmerObj.act_Name} - ${farmerObj.act_Town}`
//       : "N/A";
//     const sOnValue = farmerObj ? farmerObj.sonOf || "N/A" : "N/A";

//     setSelectedItemData({
//       billNo2: item.billNo2,
//       billDate: item.billDate,
//       partyName: item.partyName,
//       farmerName: farmerName,
//       fspno: item.fspNo,
//       bondNo: item.bondNo,
//       sOn: sOnValue,
//       place: item.town,
//       productName: item.itemName,
//       gradeName: item.gradeName || "N/A",
//       lotNo: item.bags,
//       qualityMark: item.mark,
//       bagRate: item.rate,
//       grossWeight: item.weight,
//       bagsWeight: item.tareWeight,
//       netWeight: item.netWeight,
//       amount: item.amount,
//       bagAmount: item.gAmt,
//       total: item.netAmt,
//       weights: weights,
//       serialNumbers: serialNumbers,
//     });
//     setShowPopup(true);
//   };

//   const handleClosePopup = () => setShowPopup(false);

//   const formatToThreeDecimalPlaces = (value) => {
//     const number = parseFloat(value);
//     if (isNaN(number)) return "0.000";
//     return number.toFixed(3);
//   };

//   const generateNextBillNo = () => {
//     const existingAcSaleData = JSON.parse(localStorage.getItem("AcSale")) || [];
//     const existingTransactionItems =
//       JSON.parse(localStorage.getItem("transactionItems3")) || [];

//     const allBillNos = [
//       ...existingAcSaleData.map((item) => item.billNo2),
//       ...existingTransactionItems.map((item) => item.billNo2),
//     ];

//     const maxBillNo = allBillNos.reduce((max, current) => {
//       const match = current.match(/^ACS-(\d+)$/);
//       if (match) {
//         const number = parseInt(match[1], 10);
//         return number > max ? number : max;
//       }
//       return max;
//     }, 0);

//     const newBillNo = `ACS-${maxBillNo + 1}`;
//     setBillNo2(newBillNo);
//     localStorage.setItem("billNo2", newBillNo);
//     return newBillNo;
//   };

//   const handlePopupConfirm = () => {
//     const existingAcSaleData = JSON.parse(localStorage.getItem("AcSale")) || [];
//     const matchedData = existingAcSaleData.filter(
//       (item) => item.partyName === partyName && item.billDate === selectedDate
//     );

//     const existingTransactionItems =
//       JSON.parse(localStorage.getItem("transactionItems3")) || [];

//     const updatedTransactionItems = [
//       ...existingTransactionItems,
//       ...matchedData,
//     ];
//     localStorage.setItem(
//       "transactionItems3",
//       JSON.stringify(updatedTransactionItems)
//     );

//     const remainingAcSaleData = existingAcSaleData.filter(
//       (item) =>
//         !(item.partyName === partyName && item.billDate === selectedDate)
//     );
//     localStorage.setItem("AcSale", JSON.stringify(remainingAcSaleData));

//     setTransactionItems3(updatedTransactionItems);
//     setShowAlert(false);

//     if (matchedData.length > 0) {
//       setBillNo2(matchedData[0].billNo2);
//     }

//     setButtonText("Update");
//   };

//   const handleAddItem = () => {
//     const farmerObj = farmers.find((f) => f.act_Name === actName);

//     const sOnValue = farmerObj ? farmerObj.sonOf || "N/A" : "N/A";

//     const newItem = {
//       itemName,
//       itemId,
//       gradeName: selectedGrade || "N/A",
//       partyName,
//       mark,
//       bags,
//       weight: weightInput,
//       tareWeight,
//       netWeight,
//       rate,
//       amount,
//       commission,
//       coolieCommission,
//       gAmt,
//       netAmt,
//       fspNo,
//       bondNo,
//       dropdown,
//       town,
//       farmer,
//       actName,
//       sOn: sOnValue,
//       currentDate: new Date().toISOString(),
//       modalData,
//       billNo2,
//       billDate: selectedDate,
//       compId: selectedCompany,
//     };

//     if (editIndex !== null) {
//       const updatedItems = [...transactionItems3];
//       updatedItems[editIndex] = {
//         ...newItem,
//         billNo2: updatedItems[editIndex].billNo2,
//       };
//       setTransactionItems3(updatedItems);
//       setEditIndex(null);
//     } else {
//       const updatedItems = [newItem, ...transactionItems3];
//       setTransactionItems3(updatedItems);
//     }

//     setBags("");
//     setWeightInput("0.000");
//     setRate("");
//     setAmount("0.00");
//     setCommission("0.00");
//     setCoolieCommission("0.00");
//     setGAmt(gAmtMultiplier.toString());
//     setNetAmt("0.00");
//     setFspNo("");
//     setBondNo("");
//     setMark("");
//     setWeightEditable(false);

//     setModalData({
//       weights: [],
//       serialNumbers: [],
//       startFrom: 1,
//       tareWeight: "0.00",
//       netWeight: "0.00",
//     });
//     localStorage.removeItem("acSaleModalData");
//   };

//   const handleSuggestionClick = (suggestion) => {
//     setPartyName(suggestion.AccountName);
//     setTown(suggestion.Town);
//     setSuggestions([]);
//     setPartyNameValid(true);

//     const existingData = checkForExistingData(
//       suggestion.AccountName,
//       selectedDate
//     );

//     if (existingData) {
//       setShowAlert(true);
//       setBillNo2(existingData.billNo2);
//     } else {
//       generateNextBillNo();
//     }
//   };

//   const fetchAllFarmers = useCallback(async () => {
//     try {
//       const response = await api.get(`/Master/GetFarmers`, {
//         params: { CompID: selectedCompany },
//       });
//       setFarmers(response.data);
//     } catch (error) {
//       if (error.response) {
//         console.error("Error fetching all farmer names:", error.response.data);
//       } else if (error.request) {
//         console.error(
//           "No response received for farmers request:",
//           error.request
//         );
//       } else {
//         console.error("Error setting up farmers request:", error.message);
//       }
//     }
//   }, [api, selectedCompany]);

//   const handleFarmerChange = (e) => {
//     const value = e.target.value;
//     setFarmer(value);

//     if (value.length > 0) {
//       debouncedFilterFarmers(value);
//     } else {
//       setFarmerSuggestions([]);
//     }
//   };

//   const debouncedFilterFarmers = useCallback(
//     debounce((query) => {
//       const filteredSuggestions = farmers.filter((farmer) =>
//         farmer.act_Name.toLowerCase().includes(query.toLowerCase())
//       );
//       setFarmerSuggestions(filteredSuggestions);
//     }, 300),
//     [farmers]
//   );

//   const handleFarmerSuggestionClick = (suggestion) => {
//     setFarmer(`${suggestion.act_Name} - ${suggestion.act_Town}`);
//     setactName(`${suggestion.act_Name}`);
//     setFarmerSuggestions([]);
//   };

//   const handleModalClose = (data) => {
//     if (data) {
//       const validatedWeights = data.weights
//         .filter((weight) => weight && !isNaN(parseFloat(weight)))
//         .map((weight) => parseFloat(weight).toFixed(3));

//       const serialNumbers = validatedWeights.map(
//         (_, index) => data.startFrom + index
//       );

//       const updatedModalData = {
//         weights: validatedWeights,
//         serialNumbers: serialNumbers,
//         startFrom: data.startFrom,
//         tareWeight: data.tareWeight,
//         netWeight: data.netWeight,
//       };
//       setModalData(updatedModalData);
//       localStorage.setItem("acSaleModalData", JSON.stringify(updatedModalData));

//       const totalWeight = validatedWeights.reduce(
//         (acc, weight) => acc + parseFloat(weight),
//         0
//       );
//       setWeightInput(totalWeight.toFixed(3));

//       const tareWeightValue = parseFloat(data.tareWeight) || 0.0;
//       const netWeightValue = parseFloat(data.netWeight) || 0.0;

//       setTareWeight(tareWeightValue.toFixed(3));
//       setNetWeight(netWeightValue.toFixed(3));

//       localStorage.setItem("tareWeight", tareWeightValue.toFixed(3));
//       localStorage.setItem("netWeight", netWeightValue.toFixed(3));

//       calculateAmount(rate, netWeightValue);
//       setWeightEditable(false);
//     } else {
//       setWeightInput("0.000");
//       setWeightEditable(true);
//     }
//     setShowModal(false);
//   };

//   const handleWeightChange = (e) => {
//     const value = e.target.value.replace(/[^0-9.]/g, "");
//     setWeightInput(value);
//   };

//   const handleRateChange = (e) => {
//     const value = e.target.value.replace(/[^0-9.]/g, "");
//     setRate(value);
//     calculateAmount(value, netWeight);
//   };

//   const handleBagsChange = (e) => {
//     const value = e.target.value.replace(/[^0-9]/g, "");
//     setBags(value);
//     const gAmtValue = value
//       ? (parseInt(value) * gAmtMultiplier).toString()
//       : gAmtMultiplier.toString();
//     setGAmt(gAmtValue);
//     calculateNetAmount(amount, gAmtValue);
//   };

//   const handleBagsBlur = () => {
//     if (bags && parseInt(bags, 10) > 0) {
//       setSerialCount(parseInt(bags, 10));
//       setShowModal(true);
//     }
//   };

//   const formatToTwoDecimalPlaces = (value) => {
//     const number = parseFloat(value);
//     if (isNaN(number)) return "";
//     return number.toFixed(2);
//   };

//   const handleCommissionChange = (e) => {
//     let value = e.target.value.replace(/[^0-9.]/g, "");

//     if (/^\d*\.?\d{0,2}$/.test(value)) {
//       setCommission(value);
//     } else {
//       setCommission(formatToTwoDecimalPlaces(value));
//     }
//   };

//   const handleCommissionBlur = () => {
//     setCommission(formatToTwoDecimalPlaces(commission));
//   };

//   const handleCoolieCommissionChange = (e) => {
//     let value = e.target.value.replace(/[^0-9.]/g, "");

//     if (/^\d*\.?\d{0,2}$/.test(value)) {
//       setCoolieCommission(value);
//     } else {
//       setCoolieCommission(formatToTwoDecimalPlaces(value));
//     }
//   };

//   const handleCoolieCommissionBlur = () => {
//     setCoolieCommission(formatToTwoDecimalPlaces(coolieCommission));
//   };

//   const calculateAmount = (rate, weight) => {
//     const rateValue = parseFloat(rate) || 0;
//     const weightValue = parseFloat(weight) || 0;
//     const amountValue = ((rateValue * weightValue) / 100).toFixed(2);
//     setAmount(amountValue);
//     calculateNetAmount(amountValue, gAmt);
//   };

//   const calculateNetAmount = (amountValue, gAmtValue) => {
//     const netAmount = (parseFloat(amountValue) + parseFloat(gAmtValue)).toFixed(
//       2
//     );
//     setNetAmt(netAmount);
//   };

//   const isFormValid = () => {
//     return (
//       partyName.trim() &&
//       itemName.trim() &&
//       mark.trim() &&
//       bags.trim() &&
//       rate.trim() &&
//       partyNameValid &&
//       itemNameValid
//     );
//   };

//   const resetFields = () => {
//     setItemName("");
//     setPartyName("");
//     setMark("");
//     setTown("");
//     setBags("");
//     setWeightInput("0.000");
//     setRate("");
//     setAmount("0.00");
//     setCommission("0.00");
//     setCoolieCommission("0.00");
//     setGAmt(gAmtMultiplier.toString());
//     setNetAmt("0.00");
//     setFspNo("");
//     setBondNo("");
//     setSerialCount(0);
//     setDropdown(transactionTypes.length > 0 ? transactionTypes[0].ttId : "");
//     setFarmer("");
//     setactName("");
//     setItemNameValid(true);
//     setPartyNameValid(true);
//     setSelectedGrade("");
//     setGradeOptions([]);
//     setItemId("");
//     setModalData({
//       weights: [],
//       serialNumbers: [],
//       startFrom: 1,
//       tareWeight: "0.00",
//       netWeight: "0.00",
//     });
//     setWeightEditable(false);
//     setSelectedDate(billDate);
//   };

//   const handleEdit = (index) => {
//     const item = transactionItems3[index];
//     setItemName(item.itemName || "");
//     setItemId(item.itemId || "");
//     setSelectedGrade(item.gradeName || "");
//     setPartyName(item.partyName || "");
//     setMark(item.mark || "");
//     setBags(item.bags || "");
//     setWeightInput(item.weight || "0.000");
//     setTareWeight(item.tareWeight || "0.00");
//     setNetWeight(item.netWeight || "0.00");
//     setRate(item.rate || "");
//     setAmount(item.amount || "0.00");
//     setCommission(item.commission || "0.00");
//     setCoolieCommission(item.coolieCommission || "0.00");
//     setGAmt(item.gAmt || gAmtMultiplier.toString());
//     setNetAmt(item.netAmt || "0.00");
//     setFspNo(item.fspNo || "");
//     setBondNo(item.bondNo || "");
//     setSerialCount(item.bags || 0);
//     setDropdown(item.dropdown || transactionTypes[0]?.ttId || "");
//     setTown(item.town || "");
//     setFarmer(item.farmer || "");
//     setactName(item.actName || "");
//     setModalData(
//       item.modalData || {
//         weights: [],
//         serialNumbers: [],
//         startFrom: 1,
//         tareWeight: "0.00",
//         netWeight: "0.00",
//       }
//     );
//     setEditIndex(index);
//     setWeightEditable(item.modalData?.weights.length === 0);

//     setBillNo2(item.billNo2);
//     localStorage.setItem("billNo2", item.billNo2);
//   };

//   const handleDelete = (index) => {
//     const updatedItems = transactionItems3.filter((_, i) => i !== index);
//     setTransactionItems3(updatedItems);
//   };

//   const handlePartyNameChange = (e) => {
//     const value = e.target.value;
//     setPartyName(value);

//     if (value.length >= 3) {
//       fetchPartyNameSuggestions(value);
//     } else {
//       setSuggestions([]);
//     }
//   };

//   const handleItemNameChange = (e) => {
//     const value = e.target.value;
//     setItemName(value);

//     fetchItemNameSuggestions(value);

//     const matchesSuggestion = itemSuggestions.some(
//       (suggestion) => suggestion.itemName.toLowerCase() === value.toLowerCase()
//     );

//     if (matchesSuggestion) {
//       const matchedItem = itemSuggestions.find(
//         (suggestion) =>
//           suggestion.itemName.toLowerCase() === value.toLowerCase()
//       );
//       setGAmt(matchedItem.gAmt);
//       setItemId(matchedItem.itemId);
//     } else {
//       setGAmt("0");
//       setItemId("");
//     }

//     setItemNameValid(matchesSuggestion || value.length === 0);
//   };

//   const handleItemNameFocus = () => {
//     fetchItemNameSuggestions("");
//   };

//   const handlePartyNameBlur = () => {
//     if (suggestions.length > 0) {
//       const isPartyValid = suggestions.some(
//         (suggestion) =>
//           suggestion.AccountName.toLowerCase() === partyName.toLowerCase()
//       );
//       setPartyNameValid(isPartyValid || partyName.length === 0);
//     } else {
//       setPartyNameValid(true);
//     }
//   };

//   const handleItemNameBlur = () => {
//     setItemNameValid(
//       itemSuggestions.some(
//         (suggestion) =>
//           suggestion.itemName.toLowerCase() === itemName.toLowerCase()
//       ) || itemName.length === 0
//     );
//   };

//   const fetchItemNameSuggestions = async (query) => {
//     try {
//       const response = await api.get(`/Master/ListofItems`, {
//         params: { itemName: query, CompID: selectedCompany },
//       });

//       const mappedSuggestions = response.data.map((suggestion) => ({
//         itemName: suggestion.itemName,
//         gAmt: suggestion.gunnyAmt.toString(),
//         itemId: suggestion.itemId || suggestion.itemid || suggestion.id,
//       }));
//       setItemSuggestions(mappedSuggestions);

//       if (mappedSuggestions.length > 0) {
//         setGAmt(mappedSuggestions[0].gAmt);
//         setItemId(mappedSuggestions[0].itemId);
//       } else {
//         setGAmt("0");
//         setItemId("");
//       }
//     } catch (error) {
//       if (error.response) {
//         console.error(
//           "Error fetching item name suggestions:",
//           error.response.data
//         );
//       } else if (error.request) {
//         console.error(
//           "No response received for item name suggestions:",
//           error.request
//         );
//       } else {
//         console.error(
//           "Error setting up item name suggestions request:",
//           error.message
//         );
//       }
//       setItemSuggestions([]);
//     }
//   };

//   const fetchPartyNameSuggestions = async (query) => {
//     try {
//       const response = await api.get(`/Master/Accounts`, {
//         params: { AccountName: query, CompID: selectedCompany },
//       });

//       const mappedSuggestions = response.data.map((suggestion) => ({
//         AccountName: suggestion.act_Name,
//         Town: suggestion.act_Town,
//       }));
//       setSuggestions(mappedSuggestions);
//     } catch (error) {
//       if (error.response) {
//         console.error(
//           "Error fetching party name suggestions:",
//           error.response.data
//         );
//       } else if (error.request) {
//         console.error(
//           "No response received for party name suggestions:",
//           error.request
//         );
//       } else {
//         console.error(
//           "Error setting up party name suggestions request:",
//           error.message
//         );
//       }
//       setSuggestions([]);
//     }
//   };

//   const fetchCommissionAndCoolie = async () => {
//     try {
//       const response = await api.get("/Login/GetCommissionAndCooli", {
//         params: { CompID: selectedCompany },
//       });
//       if (response.data && response.data.length > 0) {
//         const { commission, cooli } = response.data[0];
//         setCommission(commission.toFixed(2));
//         setCoolieCommission(cooli.toFixed(2));
//       }
//     } catch (error) {
//       if (error.response) {
//         console.error(
//           "Error fetching commission and coolie commission:",
//           error.response.data
//         );
//       } else if (error.request) {
//         console.error(
//           "No response received for commission and coolie:",
//           error.request
//         );
//       } else {
//         console.error(
//           "Error setting up commission and coolie request:",
//           error.message
//         );
//       }
//       setCommission("0.00");
//       setCoolieCommission("0.00");
//     }
//   };

//   const handleSearchChange = (e) => {
//     setSearchQuery(e.target.value);
//   };

//   const handleSaveAndUpdate = () => {
//     if (buttonText === "Update") {
//       const existingAcSaleData =
//         JSON.parse(localStorage.getItem("AcSale")) || [];
//       const updatedAcSaleData = [...existingAcSaleData, ...transactionItems3];
//       localStorage.setItem("AcSale", JSON.stringify(updatedAcSaleData));
//       localStorage.removeItem("transactionItems3");
//       setTransactionItems3([]);
//       setButtonText("Save & Clear");

//       generateNextBillNo();

//       resetFields();
//     } else {
//       handleSaveAndNew();
//     }
//   };

//   const handleSaveAndNew = async () => {
//     const currentDate = new Date().toISOString();
//     const itemsWithDate = transactionItems3.map((item) => ({
//       ...item,
//       currentDate,
//     }));

//     try {
//       const existingAcSaleData =
//         JSON.parse(localStorage.getItem("AcSale")) || [];

//       const updatedAcSaleData = [...existingAcSaleData, ...itemsWithDate];

//       localStorage.setItem("AcSale", JSON.stringify(updatedAcSaleData));

//       setTransactionItems3([]);

//       resetFields();

//       localStorage.removeItem("acSaleModalData");

//       generateNextBillNo();
//     } catch (error) {
//       console.error("Failed to save transaction data:", error);
//       alert("Failed to save transaction data. Please try again.");
//     }
//   };

//   useEffect(() => {
//     fetchAllFarmers();
//   }, [fetchAllFarmers]);

//   useEffect(() => {
//     const fetchGAmtMultiplier = async () => {
//       try {
//         const response = await api.get("/api/g-amt-multiplier", {
//           params: { CompID: selectedCompany },
//         });
//         setGAmtMultiplier(response.data.multiplier);
//       } catch (error) {
//         if (error.response) {
//           console.error(
//             "Error fetching G Amt Multiplier:",
//             error.response.data
//           );
//         } else if (error.request) {
//           console.error(
//             "No response received for G Amt Multiplier:",
//             error.request
//           );
//         } else {
//           console.error(
//             "Error setting up G Amt Multiplier request:",
//             error.message
//           );
//         }
//       }
//     };

//     const fetchTransactionTypes = async () => {
//       try {
//         const response = await api.get("/Master/Ttypes", {
//           params: { CompID: selectedCompany },
//         });
//         if (Array.isArray(response.data)) {
//           setTransactionTypes(response.data);
//           setDropdown(response.data.length > 0 ? response.data[0].ttId : "");
//         } else {
//           console.error(
//             "Unexpected response structure for Transaction Types:",
//             response.data
//           );
//           setTransactionTypes([]);
//         }
//       } catch (error) {
//         if (error.response) {
//           console.error(
//             "Error fetching transaction types:",
//             error.response.data
//           );
//         } else if (error.request) {
//           console.error(
//             "No response received for transaction types:",
//             error.request
//           );
//         } else {
//           console.error(
//             "Error setting up transaction types request:",
//             error.message
//           );
//         }
//         setTransactionTypes([]);
//       }
//     };

//     const initializeData = async () => {
//       await fetchGAmtMultiplier();
//       await fetchTransactionTypes();
//       await fetchCommissionAndCoolie();

//       const token = localStorage.getItem("token");
//       if (!token) {
//         console.warn("No token found. Redirecting to login.");
//         navigate("/login");
//       }

//       const savedCharges = localStorage.getItem("charges3");
//       if (savedCharges) {
//         setCharges3(JSON.parse(savedCharges));
//       }

//       const savedItems = localStorage.getItem("transactionItems3");
//       if (savedItems) {
//         setTransactionItems3(JSON.parse(savedItems));
//       }

//       fetchItemNameSuggestions("");
//       setLoading(false);
//     };

//     initializeData();
//   }, [api, navigate, selectedCompany]);

//   useEffect(() => {
//     localStorage.setItem(
//       "transactionItems3",
//       JSON.stringify(transactionItems3)
//     );
//     localStorage.setItem("billNo2", billNo2);
//     localStorage.setItem("selectedDate", selectedDate);
//   }, [transactionItems3, billNo2, selectedDate]);

//   useEffect(() => {
//     if (itemId) {
//       fetchGradeOptions(itemId);
//     } else {
//       setGradeOptions([]);
//       setSelectedGrade("");
//     }
//   }, [itemId]);

//   const fetchGradeOptions = async (itemId) => {
//     try {
//       const response = await api.get(`/Master/GetCommodities`, {
//         params: { itemid: itemId, CompID: selectedCompany },
//       });

//       if (Array.isArray(response.data)) {
//         const grades = response.data.map((grade) => ({
//           gradeId: grade.cmdTypeID,
//           gradeName: grade.cmdDesc,
//         }));
//         setGradeOptions(grades);
//       } else {
//         console.error(
//           "Unexpected API response structure for Grade Options:",
//           response.data
//         );
//         setGradeOptions([]);
//       }
//     } catch (error) {
//       if (error.response) {
//         console.error("Error fetching grade options:", error.response.data);
//       } else if (error.request) {
//         console.error("No response received for grade options:", error.request);
//       } else {
//         console.error("Error setting up grade options request:", error.message);
//       }
//       setGradeOptions([]);
//     }
//   };

//   const handleJsonDownload = async (item) => {
//     if (!Capacitor.isNativePlatform()) {
//       const json = JSON.stringify(item, null, 2);
//       const blob = new Blob([json], { type: "application/json" });
//       const url = URL.createObjectURL(blob);
//       const a = document.createElement("a");
//       a.href = url;
//       a.download = `${item.billNo || item.billNo2 || "data"}.json`;
//       a.click();
//       URL.revokeObjectURL(url);
//       return;
//     }

//     try {
//       const json = JSON.stringify(item, null, 2);
//       const fileName = `${item.billNo || item.billNo2 || "data"}.json`;

//       const result = await Filesystem.writeFile({
//         path: fileName,
//         data: json,
//         directory: Directory.Documents,
//         encoding: Encoding.UTF8,
//       });

//       if (isCordovaPrinterAvailable()) {
//         window.cordova.plugins.printer.print(
//           `<pre>${json}</pre>`,
//           "Print JSON",
//           function (res) {
//             if (res) {
//               console.log("Print Successful");
//             } else {
//               console.error("Print Failed");
//               alert("Failed to print the document.");
//             }
//           }
//         );
//       } else {
//         console.error("Cordova Printer Plugin not available.");
//         alert("Printing is not supported on this device.");
//       }
//     } catch (error) {
//       console.error("Error downloading JSON:", error);
//       alert("Failed to download JSON file");
//     }
//   };

//   const handleExcelDownload = async () => {
//     if (!Capacitor.isNativePlatform()) {
//       try {
//         const { utils, write } = await import("xlsx");

//         let dataToDownload = transactionItems3;

//         const worksheet = utils.json_to_sheet(dataToDownload);
//         const workbook = utils.book_new();
//         utils.book_append_sheet(workbook, worksheet, "AcSale");

//         const excelBuffer = write(workbook, {
//           bookType: "xlsx",
//           type: "array",
//         });

//         const blob = new Blob([excelBuffer], {
//           type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//         });

//         const url = URL.createObjectURL(blob);
//         const a = document.createElement("a");
//         a.href = url;
//         a.download = `AcSale-${selectedDate}.xlsx`;
//         a.click();
//         URL.revokeObjectURL(url);
//       } catch (error) {
//         console.error("Error downloading Excel on web:", error);
//         alert("Failed to download Excel file");
//       }
//       return;
//     }

//     try {
//       const { utils, write } = await import("xlsx");

//       let dataToDownload = transactionItems3;

//       const worksheet = utils.json_to_sheet(dataToDownload);
//       const workbook = utils.book_new();
//       utils.book_append_sheet(workbook, worksheet, "AcSale");

//       const excelBuffer = write(workbook, { bookType: "xlsx", type: "array" });

//       const base64Data = Buffer.from(excelBuffer).toString("base64");

//       const fileName = `AcSale-${selectedDate}.xlsx`;

//       const result = await Filesystem.writeFile({
//         path: fileName,
//         data: base64Data,
//         directory: Directory.Documents,
//         encoding: Encoding.UTF8,
//       });

//       const excelContent = "Excel file content cannot be printed directly.";
//       if (isCordovaPrinterAvailable()) {
//         window.cordova.plugins.printer.print(
//           excelContent,
//           "Print Excel",
//           function (res) {
//             if (res) {
//               console.log("Print Successful");
//             } else {
//               console.error("Print Failed");
//               alert("Failed to print the document.");
//             }
//           }
//         );
//       } else {
//         console.error("Cordova Printer Plugin not available.");
//         alert("Printing is not supported on this device.");
//       }
//     } catch (error) {
//       console.error("Error downloading Excel:", error);
//       alert("Failed to download Excel file");
//     }
//   };

//   const handleDateChange = (e) => {
//     setSelectedDate(e.target.value);
//   };

//   const convertDateToISO = (date) => {
//     const [dd, mm, yyyy] = date.split("-");
//     const day = dd.padStart(2, "0");
//     const month = mm.padStart(2, "0");
//     return `${yyyy}-${month}-${day}`;
//   };

//   const filteredItems = transactionItems3.filter((item) =>
//     item.farmer.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   const groupedItems = filteredItems.reduce((groups, item) => {
//     const key = `${item.itemName}-${item.farmer}-${item.mark}`;
//     if (!groups[key]) {
//       groups[key] = [];
//     }
//     groups[key].push(item);
//     return groups;
//   }, {});

//   return (
//     <>
//       <header className="main-header">
//         <NavLink to="/">
//           <img src={logo} alt="Kliq Soft Logo" className="logo" />
//         </NavLink>
//       </header>
//       <main>
//         <div className="transactions">
//           <p>Transactions</p>
//           <p>
//             <img src={sale} alt="Ac Sale Icon" />
//             AcSale
//           </p>
//         </div>

//         <div className="goback">
//           <NavLink to="/">
//             <svg
//               fill="#185492"
//               className="left-arrow"
//               xmlns="http://www.w3.org/2000/svg"
//               width={22}
//               height={22}
//               viewBox="0 0 20 20"
//             >
//               <path d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm6,11H7.38l2.376,2.375L8.342,14.789,3.607,10.055,8.342,5.322,9.756,6.736,7.49,9H16Z" />
//             </svg>
//           </NavLink>
//         </div>

//         <div className="form-container clear">
//           {loading ? (
//             <p>Loading...</p>
//           ) : (
//             <form className="form form1">
//               <div className="input-box active-grey focus">
//                 <label className="input-label">Dropdown/select list</label>
//                 <select
//                   className="input-1"
//                   value={dropdown}
//                   ref={dropdownRef}
//                   onChange={(e) => setDropdown(e.target.value)}
//                 >
//                   {Array.isArray(transactionTypes) &&
//                   transactionTypes.length > 0 ? (
//                     transactionTypes.map((type) => (
//                       <option key={type.ttId} value={type.ttId}>
//                         {type.ttName}
//                       </option>
//                     ))
//                   ) : (
//                     <option>No options available</option>
//                   )}
//                 </select>
//               </div>

//               <div className="row row-bill-info">
//                 <div className="input-box col focus">
//                   <label className="input-label">Bill No.</label>
//                   <input
//                     type="text"
//                     className="input-1"
//                     value={billNo2}
//                     readOnly
//                   />
//                 </div>
//                 <div className="input-box col focus">
//                   <label className="input-label">Bill Date</label>
//                   <input
//                     type="text"
//                     className="input-1"
//                     value={billDate}
//                     readOnly
//                   />
//                 </div>
//               </div>

//               <div className="row">
//                 <div className="input-box col focus">
//                   <label
//                     className={`input-label ${
//                       !partyNameValid ? "label-invalid" : ""
//                     }`}
//                   >
//                     Party Name *
//                   </label>
//                   <input
//                     type="text"
//                     id="inputField"
//                     className={`input-1 ${
//                       !partyNameValid ? "input-invalid" : ""
//                     }`}
//                     ref={partyNameRef}
//                     required
//                     value={partyName || ""}
//                     onChange={handlePartyNameChange}
//                     onBlur={handlePartyNameBlur}
//                   />
//                   {suggestions.length > 0 && (
//                     <ul className="suggestions-list">
//                       {suggestions.map((suggestion, index) => (
//                         <li
//                           key={index}
//                           onClick={() => handleSuggestionClick(suggestion)}
//                         >
//                           {suggestion.AccountName}
//                         </li>
//                       ))}
//                     </ul>
//                   )}
//                   {!partyNameValid && (
//                     <div className="invalid-feedback">
//                       Party name does not exist.{" "}
//                       <NavLink to="/accountpage" className="add-new">
//                         Add New Account
//                       </NavLink>
//                     </div>
//                   )}
//                 </div>
//                 <div className="input-box col focus">
//                   <label className="input-label">Town</label>
//                   <input
//                     type="text"
//                     className="input-1"
//                     ref={townRef}
//                     value={town || ""}
//                     onChange={(e) => setTown(e.target.value)}
//                   />
//                 </div>
//               </div>
//               <div className="input-box focus">
//                 <label className="input-label">Item Name *</label>
//                 <select
//                   className={`input-1 ${!itemNameValid ? "input-invalid" : ""}`}
//                   ref={itemNameRef}
//                   value={itemName || ""}
//                   onChange={handleItemNameChange}
//                   onFocus={handleItemNameFocus}
//                   onBlur={handleItemNameBlur}
//                 >
//                   <option value="">Select an item</option>
//                   {itemSuggestions.map((suggestion, index) => (
//                     <option
//                       key={suggestion.itemId || index}
//                       value={suggestion.itemName}
//                     >
//                       {suggestion.itemName}
//                     </option>
//                   ))}
//                 </select>
//                 {!itemNameValid && (
//                   <div className="invalid-feedback">
//                     Item name does not exist.
//                   </div>
//                 )}
//               </div>

//               <div className="input-box focus">
//                 <label className="input-label">Grade Name</label>
//                 <select
//                   className="input-1"
//                   value={selectedGrade}
//                   onChange={(e) => setSelectedGrade(e.target.value)}
//                 >
//                   <option value="">Select Grade</option>
//                   {gradeOptions.map((grade) => (
//                     <option key={grade.gradeId} value={grade.gradeName}>
//                       {grade.gradeName}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             </form>
//           )}

//           <form className="form form2">
//             <div className="row row1">
//               <div className="input-box col col1 focus">
//                 <label className="input-label">Farmer Name</label>
//                 <input
//                   type="text"
//                   className="input-1"
//                   value={farmer}
//                   onChange={handleFarmerChange}
//                   placeholder="Start typing a farmer name"
//                 />
//                 {farmerSuggestions.length > 0 && (
//                   <ul className="suggestions-list">
//                     {farmerSuggestions.map((suggestion, index) => (
//                       <li
//                         key={index}
//                         onClick={() => handleFarmerSuggestionClick(suggestion)}
//                       >
//                         {/* {suggestion.AccountName} - {suggestion.Town} */}
//                         {suggestion.act_Name} - {suggestion.act_Town}
//                       </li>
//                     ))}
//                   </ul>
//                 )}
//               </div>
//               <div className="input-box col col2 focus">
//                 <label className="input-label">Mark *</label>
//                 <input
//                   type="text"
//                   className="input-1"
//                   ref={markRef}
//                   value={mark || ""}
//                   onChange={(e) => setMark(e.target.value)}
//                 />
//               </div>
//             </div>
//             <div className="row row2">
//               <div className="input-box col col1 focus">
//                 <label className="input-label">Bags *</label>
//                 <input
//                   type="text"
//                   className="input-1"
//                   ref={bagsRef}
//                   value={bags || ""}
//                   onChange={handleBagsChange}
//                   inputMode="numeric"
//                   onBlur={handleBagsBlur}
//                 />
//               </div>
//               <div className="input-box col col3 focus">
//                 <label className="input-label">Weight</label>
//                 <input
//                   type="text"
//                   className="input-1 numeric-input three-decimal"
//                   ref={weightRef}
//                   inputMode="numeric"
//                   value={weightInput}
//                   onInput={handleWeightChange}
//                   onBlur={() =>
//                     setWeightInput(formatToThreeDecimalPlaces(weightInput))
//                   }
//                   readOnly={!weightEditable}
//                 />
//               </div>
//             </div>
//             <div className="row row3">
//               <div className="input-box col col1 focus">
//                 <label className="input-label">Rate *</label>
//                 <input
//                   type="text"
//                   className="input-1 numeric-input"
//                   ref={rateRef}
//                   value={rate || ""}
//                   inputMode="numeric"
//                   onChange={handleRateChange}
//                 />
//               </div>
//               <div className="input-box col col2 focus">
//                 <label className="input-label">Amount</label>
//                 <input
//                   type="text"
//                   className="input-1 numeric-input"
//                   value={amount}
//                   readOnly
//                 />
//               </div>
//               <div className="input-box col col3 focus">
//                 <label className="input-label">Commission</label>
//                 <input
//                   type="text"
//                   className="input-1 numeric-input"
//                   value={commission}
//                   onChange={handleCommissionChange}
//                   onBlur={handleCommissionBlur}
//                 />
//               </div>
//             </div>
//             <div className="row row4">
//               <div className="input-box col col1 focus">
//                 <label className="input-label">Coolie Commission</label>
//                 <input
//                   type="text"
//                   className="input-1 numeric-input"
//                   value={coolieCommission}
//                   onChange={handleCoolieCommissionChange}
//                   onBlur={handleCoolieCommissionBlur}
//                 />
//               </div>
//               <div className="input-box col col2 focus">
//                 <label className="input-label">G.Amt</label>
//                 <input
//                   type="text"
//                   className="input-1 numeric-input"
//                   value={gAmt}
//                   readOnly
//                 />
//               </div>
//               <div className="input-box col col3 focus">
//                 <label className="input-label">Net Amt</label>
//                 <input
//                   type="text"
//                   className="input-1 numeric-input"
//                   value={netAmt}
//                   readOnly
//                 />
//               </div>
//             </div>
//             <div className="row row5">
//               <div className="input-box col col1 focus">
//                 <label className="input-label">FSP No.</label>
//                 <input
//                   type="text"
//                   className="input-1 numeric-input"
//                   value={fspNo}
//                   onChange={(e) => setFspNo(e.target.value)}
//                 />
//               </div>

//               <div className="input-box col col1 focus">
//                 <label className="input-label">Bond No.</label>
//                 <input
//                   type="text"
//                   className="input-1 numeric-input"
//                   value={bondNo}
//                   onChange={(e) => setBondNo(e.target.value)}
//                 />
//               </div>
//             </div>
//             <button
//               type="button"
//               id="submitButton"
//               className={`btn btn-primary pull-right ${
//                 !isFormValid() ? "btn-disabled" : ""
//               }`}
//               disabled={!isFormValid()}
//               onClick={handleAddItem}
//             >
//               {editIndex !== null ? "Update Item" : "Add Item"}
//             </button>
//             <div className="listAndSave">
//               <button
//                 type="button"
//                 className="btn btn-save"
//                 onClick={handleSaveAndUpdate}
//               >
//                 {buttonText}
//               </button>
//             </div>
//           </form>
//         </div>

//         <div
//           className="item-search"
//           style={{ marginBottom: filteredItems.length > 0 ? "0" : "3rem" }}
//         >
//           <input
//             type="text"
//             name="search"
//             id="search"
//             placeholder="Farmer Name"
//             value={searchQuery || ""}
//             onChange={handleSearchChange}
//           />
//           <button className="btn btn-search">View</button>
//         </div>

//         {filteredItems.length > 0 && (
//           <div
//             className="item-list"
//             style={{ overflowX: "auto", marginBottom: "3.5rem" }}
//           >
//             <table>
//               <thead>
//                 <tr>
//                   <th scope="col">Bags</th>
//                   <th scope="col">Weight</th>
//                   <th scope="col">Rate</th>
//                   <th scope="col">G.Amt</th>
//                   <th scope="col">Net Amt</th>
//                   <th scope="col">FSP No.</th>
//                   <th scope="col">Bond No.</th>
//                   <th
//                     scope="col"
//                     className="action"
//                     colSpan={3}
//                     style={{ opacity: 0 }}
//                   >
//                     Action
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {Object.entries(groupedItems).map(
//                   ([key, items], groupIndex) => (
//                     <React.Fragment key={`group-${groupIndex}`}>
//                       <tr className="trHeader">
//                         <td colSpan={8}>
//                           {items[0].itemName} - {items[0].farmer}
//                         </td>
//                         <td colSpan={5} className="mark">
//                           {items[0].mark}
//                         </td>
//                       </tr>
//                       {items.map((item, index) => (
//                         <tr key={`data-${groupIndex}-${index}`}>
//                           <td>{item.bags}</td>
//                           <td>{item.weight}</td>
//                           <td>{item.rate}</td>
//                           <td>{item.gAmt}</td>
//                           <td>{item.netAmt}</td>
//                           <td>{item.fspNo}</td>
//                           <td>{item.bondNo}</td>
//                           <td>
//                             <img
//                               id="itemImg"
//                               src={preview}
//                               alt="Preview Download"
//                               style={{ cursor: "pointer" }}
//                               onClick={() => handleShowPopup(item)}
//                             />
//                           </td>
//                           <td>
//                             <img
//                               src={editIcon}
//                               alt="Edit"
//                               onClick={() => handleEdit(index)}
//                               style={{ cursor: "pointer" }}
//                             />
//                           </td>
//                           <td>
//                             <img
//                               src={trashIcon}
//                               alt="Delete"
//                               onClick={() => handleDelete(index)}
//                               style={{ cursor: "pointer" }}
//                             />
//                           </td>
//                         </tr>
//                       ))}
//                     </React.Fragment>
//                   )
//                 )}
//               </tbody>
//               <tfoot>
//                 <tr>
//                   <th colSpan={10}>No. of Items: {transactionItems3.length}</th>
//                 </tr>
//               </tfoot>
//             </table>
//           </div>
//         )}
//       </main>
//       {showModal && (
//         <ModalWindow
//           serialCount={serialCount}
//           onClose={handleModalClose}
//           initialWeights={modalData.weights}
//           initialSerials={modalData.serialNumbers}
//           startSerial={modalData.startFrom}
//         />
//       )}
//       {chargeModalOpen && (
//         <ChargeModal
//           isOpen={chargeModalOpen}
//           onClose={() => setChargeModalOpen(false)}
//           chargesData={charges3}
//           onSave={(data) => setCharges3(data)}
//         />
//       )}
//       {showAlert && (
//         <>
//           <div
//             className="alert-popup-overlay"
//             onClick={() => setShowAlert(false)}
//           ></div>
//           <div className="alert-popup">
//             <p>Party Name already exists on this day.</p>
//             <button onClick={handlePopupConfirm}>OK</button>
//           </div>
//         </>
//       )}
//       {showPopup && (
//         <WeightListPopup
//           onClose={handleClosePopup}
//           itemData={selectedItemData}
//         />
//       )}
//     </>
//   );
// }

// AcSale.jsx

// import React, {
//   useState,
//   useEffect,
//   useRef,
//   useCallback,
//   useMemo,
// } from "react";
// import { NavLink, useNavigate } from "react-router-dom";
// import axios from "axios";
// import debounce from "lodash.debounce";
// import "./styles/Form.css";
// import "./styles/ItemsList.css";
// import "./styles/AccountAndTransactions.css";
// import "./styles/Popup.css";
// import logo from "../images/Logo.jpg";
// import preview from "../images/preview.svg";
// import editIcon from "../images/edit.svg";
// import trashIcon from "../images/trash.svg";
// import sale from "../images/sale.svg";
// import ModalWindow from "./ModalWindow";
// import ChargeModal from "./ChargeModal";
// import { Buffer } from "buffer";
// import { Capacitor } from "@capacitor/core";
// import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";

// // Polyfill for Buffer
// window.Buffer = Buffer;

// // Utility function to check if Cordova Printer Plugin is available
// const isCordovaPrinterAvailable = () => {
//   return (
//     window.cordova &&
//     window.cordova.plugins &&
//     window.cordova.plugins.printer &&
//     typeof window.cordova.plugins.printer.print === "function"
//   );
// };

// // Styles for the WeightListPopup
// const styles = {
//   overlay: {
//     position: "fixed",
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     backgroundColor: "rgba(0,0,0,0.5)",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     zIndex: 1000,
//   },
//   popup: {
//     backgroundColor: "#fff",
//     padding: "20px",
//     borderRadius: "8px",
//     width: "80%",
//     maxHeight: "90%",
//     overflowY: "auto",
//     position: "relative",
//     boxShadow: "0 5px 15px rgba(0,0,0,0.3)",
//   },
//   closeButton: {
//     position: "absolute",
//     top: "10px",
//     right: "15px",
//     background: "transparent",
//     border: "none",
//     fontSize: "24px",
//     cursor: "pointer",
//   },
//   printButton: {
//     background: "#185492",
//     color: "#fff",
//     fontSize: "14px",
//     border: "none",
//     padding: "10px 15px",
//     cursor: "pointer",
//     boxShadow: "0px 5px 15px rgba(0,0,0,0.3)",
//     marginBottom: "20px",
//   },
//   contentContainer: {
//     textAlign: "left",
//   },
//   header: {
//     textAlign: "center",
//     marginBottom: "20px",
//   },
//   table: {
//     borderTop: "2px dashed #202020",
//     borderBottom: "2px dashed #202020",
//     borderCollapse: "collapse",
//     width: "100%",
//     marginTop: "10px",
//     marginBottom: "10px",
//   },
//   tbody: {
//     borderTop: "2px dashed #202020",
//     borderBottom: "2px dashed #202020",
//     textAlign: "center",
//   },
// };

// // Internal WeightListPopup Component
// const WeightListPopup = ({ onClose, itemData }) => {
//   const handlePrint = () => {
//     const printContent = document.getElementById(
//       "weight-list-popup-content"
//     ).innerHTML;

//     if (Capacitor.isNativePlatform()) {
//       if (isCordovaPrinterAvailable()) {
//         window.cordova.plugins.printer.print(
//           printContent,
//           "Print Document",
//           function (res) {
//             if (res) {
//               console.log("Print Successful");
//             } else {
//               console.error("Print Failed");
//               alert("Failed to print the document.");
//             }
//           }
//         );
//       } else {
//         console.error("Cordova Printer Plugin not available.");
//         alert("Printing is not supported on this device.");
//       }
//     } else {
//       let iframeDoc = document.createElement("iframe");
//       iframeDoc.style.position = "fixed";
//       iframeDoc.style.right = "0";
//       iframeDoc.style.bottom = "0";
//       iframeDoc.style.width = "0";
//       iframeDoc.style.height = "0";
//       iframeDoc.style.border = "0";
//       document.body.appendChild(iframeDoc);

//       let iframeDocument = iframeDoc.contentWindow || iframeDoc.contentDocument;
//       if (iframeDocument.document) iframeDocument = iframeDocument.document;

//       iframeDocument.open();
//       iframeDocument.write(`
//         <html>
//           <head>
//             <title>Print Document</title>
//             <style>
//               body { font-family: Arial, sans-serif; padding: 20px; }
//               table { width: 100%; border-collapse: collapse; }
//               th, td { border: 1px solid #000; padding: 8px; text-align: left; }
//               th { background-color: #f2f2f2; }
//             </style>
//           </head>
//           <body>
//             ${printContent}
//           </body>
//         </html>
//       `);
//       iframeDocument.close();

//       iframeDoc.onload = () => {
//         setTimeout(() => {
//           iframeDoc.contentWindow.focus();
//           iframeDoc.contentWindow.print();
//         }, 500);
//       };
//     }
//   };

//   if (!itemData) return null;

//   return (
//     <div id="popup-overlay" style={styles.overlay}>
//       <div className="weight-list-popup" style={styles.popup}>
//         <button
//           className="close-button"
//           onClick={onClose}
//           style={styles.closeButton}
//         >
//           &times;
//         </button>
//         <button
//           className="print-button"
//           onClick={handlePrint}
//           style={styles.printButton}
//         >
//           Print
//         </button>
//         <div
//           className="weight_list_container"
//           id="weight-list-popup-content"
//           style={styles.contentContainer}
//         >
//           <h2 style={styles.header}>AC Sale</h2>
//           <p>
//             <strong>Bill No:</strong> {itemData.billNo2}
//           </p>
//           <p>
//             <strong>Bill Date:</strong> {itemData.billDate}
//           </p>
//           <p>
//             <strong>Bond No:</strong> {itemData.bondNo}
//           </p>
//           <p>
//             <strong>FSP:</strong> {itemData.fspno}
//           </p>
//           <p>
//             <strong>Party Name:</strong> {itemData.partyName} / {itemData.place}
//           </p>
//           <p>
//             <strong>Farmer Name:</strong> {itemData.farmerName}
//           </p>
//           <p>
//             <strong>S/o:</strong> {itemData.sOn}
//           </p>
//           <p>
//             <strong>Place:</strong> {itemData.place}
//           </p>
//           <p>
//             <strong>Product Name:</strong> {itemData.productName}
//           </p>
//           <p>
//             <strong>Grade Name:</strong> {itemData.gradeName}
//           </p>
//           <p>
//             <strong>LOT NO:</strong> {itemData.lotNo}
//           </p>
//           <p>
//             <strong>Quality Mark:</strong> {itemData.qualityMark}
//           </p>
//           <p>
//             <strong>Rate:</strong> {itemData.bagRate}
//           </p>
//           <p>
//             <strong>Gross Weight:</strong> {itemData.grossWeight}
//           </p>
//           <p>{/* <strong>Bags Weight:</strong> {itemData.bagsWeight} */}</p>
//           <p>
//             <strong>Tare Weight:</strong> {itemData.bagsWeight}
//           </p>
//           <p>
//             <strong>Net Weight:</strong> {itemData.netWeight}
//           </p>
//           <br />
//           <p>
//             <strong>Amount:</strong> Rs. {itemData.amount}
//           </p>
//           {/* <p>
//             <strong>Bag Amount:</strong> Rs. {itemData.bagAmount}
//           </p> */}
//           {/* <p>
//             <strong>Gunny Amount:</strong> Rs. {itemData.bagAmount}
//           </p>
//           <p>
//             <strong>Total:</strong> Rs. {itemData.total}
//           </p> */}
//           <table style={styles.table}>
//             <thead>
//               <tr>
//                 <th scope="col">S.NO</th>
//                 <th scope="col">Quantity(Kg)</th>
//               </tr>
//             </thead>
//             <tbody style={styles.tbody}>
//               {itemData.serialNumbers.map((serial, index) => (
//                 <tr key={index}>
//                   <td scope="row">{serial}</td>
//                   <td>{itemData.weights[index]}</td>
//                 </tr>
//               ))}
//             </tbody>
//             <tfoot>
//               <tr>
//                 <th scope="row">Net Wgt:</th>
//                 <th scope="row" className="total-Qty">
//                   {itemData.netWeight}
//                 </th>
//               </tr>
//               {/* <tr>
//                 <th scope="row">Net Wgt:</th>
//                 <th scope="row" className="total-Qty">
//                   {itemData.netWeight}
//                 </th>
//               </tr> */}
//             </tfoot>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// };

// function AcSale() {
//   const [showPopup, setShowPopup] = useState(false);
//   const [selectedItemData, setSelectedItemData] = useState(null);
//   const [showAlert, setShowAlert] = useState(false);
//   const [farmers, setFarmers] = useState([]);
//   const [farmerSuggestions, setFarmerSuggestions] = useState([]);
//   const [billNo2, setBillNo2] = useState(
//     localStorage.getItem("billNo2") || "ACS-1"
//   );
//   const [billDate, setBillDate] = useState(() => {
//     const currentDate = new Date();
//     return `${String(currentDate.getDate()).padStart(2, "0")}-${String(
//       currentDate.getMonth() + 1
//     ).padStart(2, "0")}-${currentDate.getFullYear()}`;
//   });
//   const [selectedDate, setSelectedDate] = useState(
//     localStorage.getItem("selectedDate") || billDate
//   );
//   const [partyName, setPartyName] = useState("");
//   const [showModal, setShowModal] = useState(false);
//   const [serialCount, setSerialCount] = useState(0);
//   const [weightInput, setWeightInput] = useState("0.000");
//   const [tareWeight, setTareWeight] = useState(
//     localStorage.getItem("tareWeight") || "0.00"
//   );
//   const [netWeight, setNetWeight] = useState(
//     localStorage.getItem("netWeight") || "0.00"
//   );
//   const [bags, setBags] = useState("");
//   const [rate, setRate] = useState("");
//   const [amount, setAmount] = useState("0.00");
//   const [coolieCommission, setCoolieCommission] = useState("0.00");
//   const [commission, setCommission] = useState("0.00");
//   const [gAmt, setGAmt] = useState("0");
//   const [netAmt, setNetAmt] = useState("0.00");
//   const [fspNo, setFspNo] = useState("");
//   const [bondNo, setBondNo] = useState("");
//   const [transactionItems3, setTransactionItems3] = useState([]);
//   const [itemName, setItemName] = useState("");
//   const [itemSuggestions, setItemSuggestions] = useState([]);
//   const [mark, setMark] = useState("");
//   const [dropdown, setDropdown] = useState("");
//   const [town, setTown] = useState("");
//   const [farmer, setFarmer] = useState("");
//   const [actName, setactName] = useState("");
//   const [editIndex, setEditIndex] = useState(null);
//   const [searchQuery, setSearchQuery] = useState("");
//   const [suggestions, setSuggestions] = useState([]);
//   const [partyNameValid, setPartyNameValid] = useState(true);
//   const [itemNameValid, setItemNameValid] = useState(true);
//   const [gAmtMultiplier, setGAmtMultiplier] = useState(0);
//   const [transactionTypes, setTransactionTypes] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [chargeModalOpen, setChargeModalOpen] = useState(false);
//   const [charges3, setCharges3] = useState([
//     {
//       id: 1,
//       chargeName: "TCS P",
//       rate: "0.100",
//       value: "0.000",
//       adjAcName: "TCS Purchase A/C",
//       gst: "0",
//       gstAmt: "0.00",
//       total: "0.000",
//     },
//     {
//       id: 2,
//       chargeName: "Round off",
//       rate: "1.000",
//       value: "0.000",
//       adjAcName: "ROUND OFF",
//       gst: "0",
//       gstAmt: "0.00",
//       total: "0.000",
//     },
//   ]);
//   const [buttonText, setButtonText] = useState("Save & New");
//   const [weightEditable, setWeightEditable] = useState(false);

//   const [gradeOptions, setGradeOptions] = useState([]);
//   const [selectedGrade, setSelectedGrade] = useState("");
//   const [itemId, setItemId] = useState("");

//   const [modalData, setModalData] = useState(
//     JSON.parse(localStorage.getItem("acSaleModalData")) || {
//       weights: [],
//       startFrom: 1,
//     }
//   );

//   const navigate = useNavigate();
//   const partyNameRef = useRef(null);
//   const itemNameRef = useRef(null);
//   const markRef = useRef(null);
//   const bagsRef = useRef(null);
//   const weightRef = useRef(null);
//   const rateRef = useRef(null);
//   const dropdownRef = useRef(null);
//   const townRef = useRef(null);
//   const farmerRef = useRef(null);
//   const dateRef = useRef(null);

//   // const api = useMemo(() => {
//   //   const baseURL1 = localStorage.getItem("baseURL");
//   //   const instance = axios.create({
//   //     baseURL: baseURL1,
//   //   });

//   const api = useMemo(() => {
//     const baseURL = "https://Kliqss.in/CSAPI";
//     const instance = axios.create({
//       baseURL: baseURL,
//     });

//     instance.interceptors.request.use(
//       (config) => {
//         const token = localStorage.getItem("token");
//         if (token) {
//           config.headers["Authorization"] = `Bearer ${token}`;
//         }
//         return config;
//       },
//       (error) => Promise.reject(error)
//     );

//     return instance;
//   }, []);

//   const checkForExistingData = (partyName, billDate) => {
//     const existingAcSaleData = JSON.parse(localStorage.getItem("AcSale")) || [];
//     return existingAcSaleData.find(
//       (item) => item.partyName === partyName && item.billDate === billDate
//     );
//   };

//   const handleShowPopup = (item) => {
//     const weights = item.modalData?.weights || [];
//     const serialNumbers = weights.map(
//       (_, index) => parseInt(item.modalData?.startFrom || 1) + index
//     );

//     const farmerObj = farmers.find(
//       (farmerItem) => farmerItem.act_Name === item.actName
//     );

//     const farmerName = farmerObj
//       ? farmerObj.act_Name + " - " + farmerObj.act_Town
//       : "N/A";
//     const sOnValue = farmerObj ? farmerObj.sonOf || "N/A" : "N/A";

//     setSelectedItemData({
//       billNo2: item.billNo2,
//       billDate: item.billDate,
//       partyName: item.partyName,
//       farmerName: farmerName,
//       // farmerName: item.farmer,
//       fspno: item.fspNo,
//       bondNo: item.bondNo,
//       sOn: sOnValue,
//       place: item.town,
//       productName: item.itemName,
//       gradeName: item.gradeName || "N/A",
//       lotNo: item.bags,
//       qualityMark: item.mark,
//       bagRate: item.rate,
//       grossWeight: item.weight,
//       bagsWeight: item.tareWeight,
//       netWeight: item.netWeight,
//       amount: item.amount,
//       bagAmount: item.gAmt,
//       total: item.netAmt,
//       weights: weights,
//       serialNumbers: serialNumbers,
//     });
//     setShowPopup(true);
//   };

//   const handleClosePopup = () => setShowPopup(false);

//   const formatToThreeDecimalPlaces = (value) => {
//     const number = parseFloat(value);
//     if (isNaN(number)) return "0.000";
//     return number.toFixed(3);
//   };

//   const generateNextBillNo = () => {
//     const existingAcSaleData = JSON.parse(localStorage.getItem("AcSale")) || [];
//     const existingTransactionItems =
//       JSON.parse(localStorage.getItem("transactionItems3")) || [];

//     const allBillNos = [
//       ...existingAcSaleData.map((item) => item.billNo2),
//       ...existingTransactionItems.map((item) => item.billNo2),
//     ];

//     const maxBillNo = allBillNos.reduce((max, current) => {
//       const match = current.match(/^ACS-(\d+)$/);
//       if (match) {
//         const number = parseInt(match[1], 10);
//         return number > max ? number : max;
//       }
//       return max;
//     }, 0);

//     const newBillNo = `ACS-${maxBillNo + 1}`;
//     setBillNo2(newBillNo);
//     localStorage.setItem("billNo2", newBillNo);
//     return newBillNo;
//   };

//   const handlePopupConfirm = () => {
//     const existingAcSaleData = JSON.parse(localStorage.getItem("AcSale")) || [];
//     const matchedData = existingAcSaleData.filter(
//       (item) => item.partyName === partyName && item.billDate === selectedDate
//     );

//     const existingTransactionItems =
//       JSON.parse(localStorage.getItem("transactionItems3")) || [];

//     const updatedTransactionItems = [
//       ...existingTransactionItems,
//       ...matchedData,
//     ];
//     localStorage.setItem(
//       "transactionItems3",
//       JSON.stringify(updatedTransactionItems)
//     );

//     const remainingAcSaleData = existingAcSaleData.filter(
//       (item) =>
//         !(item.partyName === partyName && item.billDate === selectedDate)
//     );
//     localStorage.setItem("AcSale", JSON.stringify(remainingAcSaleData));

//     setTransactionItems3(updatedTransactionItems);
//     setShowAlert(false);

//     if (matchedData.length > 0) {
//       setBillNo2(matchedData[0].billNo2);
//     }

//     setButtonText("Update");
//   };

//   const handleAddItem = () => {
//     const farmerObj = farmers.find((f) => f.act_Name === actName);

//     const sOnValue = farmerObj ? farmerObj.sonOf || "N/A" : "N/A";

//     const newItem = {
//       itemName,
//       itemId,
//       gradeName: selectedGrade || "N/A",
//       partyName,
//       mark,
//       bags,
//       weight: weightInput,
//       tareWeight,
//       netWeight,
//       rate,
//       amount,
//       commission,
//       coolieCommission,
//       gAmt,
//       netAmt,
//       fspNo,
//       bondNo,
//       dropdown,
//       town,
//       farmer,
//       actName, // Newly Added
//       sOn: sOnValue,
//       currentDate: new Date().toISOString(),
//       modalData,
//       billNo2,
//       billDate: selectedDate,
//     };

//     if (editIndex !== null) {
//       const updatedItems = [...transactionItems3];
//       updatedItems[editIndex] = {
//         ...newItem,
//         billNo2: updatedItems[editIndex].billNo2,
//       };
//       setTransactionItems3(updatedItems);
//       setEditIndex(null);
//     } else {
//       const updatedItems = [newItem, ...transactionItems3];
//       setTransactionItems3(updatedItems);
//     }

//     setBags("");
//     setWeightInput("0.000");
//     setRate("");
//     setAmount("0.00");
//     setCommission("0.00");
//     setCoolieCommission("0.00");
//     setGAmt(gAmtMultiplier.toString());
//     setNetAmt("0.00");
//     setFspNo("");
//     setBondNo("");
//     setMark("");
//     setWeightEditable(false);

//     setModalData({ weights: [], startFrom: 1 });
//     localStorage.removeItem("acSaleModalData");
//   };

//   const handleSuggestionClick = (suggestion) => {
//     setPartyName(suggestion.AccountName);
//     setTown(suggestion.Town);
//     setSuggestions([]);
//     setPartyNameValid(true);

//     const existingData = checkForExistingData(
//       suggestion.AccountName,
//       selectedDate
//     );

//     if (existingData) {
//       setShowAlert(true);
//       setBillNo2(existingData.billNo2);
//     } else {
//       generateNextBillNo();
//     }
//   };

//   useEffect(() => {
//     const fetchAllFarmers = async () => {
//       try {
//         const response = await api.get(`/Master/GetFarmers`);
//         setFarmers(response.data);
//       } catch (error) {
//         console.error("Error fetching all farmer names:", error);
//       }
//     };

//     fetchAllFarmers();
//   }, [api]);

//   const handleFarmerChange = (e) => {
//     const value = e.target.value;
//     setFarmer(value);

//     if (value.length > 0) {
//       debouncedFilterFarmers(value);
//     } else {
//       setFarmerSuggestions([]);
//     }
//   };

//   const debouncedFilterFarmers = useCallback(
//     debounce((query) => {
//       const filteredSuggestions = farmers.filter((farmer) =>
//         farmer.act_Name.toLowerCase().includes(query.toLowerCase())
//       );
//       setFarmerSuggestions(filteredSuggestions);
//     }, 300),
//     [farmers]
//   );

//   const handleFarmerSuggestionClick = (suggestion) => {
//     setFarmer(`${suggestion.act_Name} - ${suggestion.act_Town}`);
//     setactName(`${suggestion.act_Name}`);
//     setFarmerSuggestions([]);
//   };

//   const handleModalClose = (data) => {
//     if (data) {
//       const validatedWeights = data.weights
//         .filter((weight) => weight && !isNaN(parseFloat(weight)))
//         .map((weight) => parseFloat(weight).toFixed(3));

//       const serialNumbers = validatedWeights.map(
//         (_, index) => data.startFrom + index
//       );

//       const updatedModalData = {
//         weights: validatedWeights,
//         serialNumbers: serialNumbers,
//         startFrom: data.startFrom,
//         tareWeight: data.tareWeight,
//         netWeight: data.netWeight,
//       };
//       setModalData(updatedModalData);
//       localStorage.setItem("acSaleModalData", JSON.stringify(updatedModalData));

//       const totalWeight = validatedWeights.reduce(
//         (acc, weight) => acc + parseFloat(weight),
//         0
//       );
//       setWeightInput(totalWeight.toFixed(3));

//       const tareWeightValue = parseFloat(data.tareWeight) || 0.0;
//       const netWeightValue = parseFloat(data.netWeight) || 0.0;

//       setTareWeight(tareWeightValue.toFixed(3));
//       setNetWeight(netWeightValue.toFixed(3));

//       localStorage.setItem("tareWeight", tareWeightValue.toFixed(3));
//       localStorage.setItem("netWeight", netWeightValue.toFixed(3));

//       // calculateAmount(rate, totalWeight);
//       calculateAmount(rate, netWeightValue);
//       setWeightEditable(false);
//     } else {
//       setWeightInput("0.000");
//       setWeightEditable(true);
//     }
//     setShowModal(false);
//   };

//   const handleWeightChange = (e) => {
//     const value = e.target.value.replace(/[^0-9.]/g, "");
//     setWeightInput(value);
//   };

//   const handleRateChange = (e) => {
//     const value = e.target.value.replace(/[^0-9.]/g, "");
//     setRate(value);
//     // calculateAmount(value, weightInput);
//     calculateAmount(value, netWeight);
//   };

//   const handleBagsChange = (e) => {
//     const value = e.target.value.replace(/[^0-9]/g, "");
//     setBags(value);
//     const gAmtValue = value
//       ? (parseInt(value) * gAmtMultiplier).toString()
//       : gAmtMultiplier.toString();
//     setGAmt(gAmtValue);
//     calculateNetAmount(amount, gAmtValue);
//   };

//   const handleBagsBlur = () => {
//     if (bags && parseInt(bags, 10) > 0) {
//       setSerialCount(parseInt(bags, 10));
//       setShowModal(true);
//     }
//   };

//   const formatToTwoDecimalPlaces = (value) => {
//     const number = parseFloat(value);
//     if (isNaN(number)) return "";
//     return number.toFixed(2);
//   };

//   const handleCommissionChange = (e) => {
//     let value = e.target.value.replace(/[^0-9.]/g, "");

//     if (/^\d*\.?\d{0,2}$/.test(value)) {
//       setCommission(value);
//     } else {
//       setCommission(formatToTwoDecimalPlaces(value));
//     }
//   };

//   const handleCommissionBlur = () => {
//     setCommission(formatToTwoDecimalPlaces(commission));
//   };

//   const handleCoolieCommissionChange = (e) => {
//     let value = e.target.value.replace(/[^0-9.]/g, "");

//     if (/^\d*\.?\d{0,2}$/.test(value)) {
//       setCoolieCommission(value);
//     } else {
//       setCoolieCommission(formatToTwoDecimalPlaces(value));
//     }
//   };

//   const handleCoolieCommissionBlur = () => {
//     setCoolieCommission(formatToTwoDecimalPlaces(coolieCommission));
//   };

//   const calculateAmount = (rate, weight) => {
//     const rateValue = parseFloat(rate) || 0;
//     const weightValue = parseFloat(weight) || 0;
//     const amountValue = ((rateValue * weightValue) / 100).toFixed(2);
//     setAmount(amountValue);
//     calculateNetAmount(amountValue, gAmt);
//   };

//   const calculateNetAmount = (amountValue, gAmtValue) => {
//     const netAmount = (parseFloat(amountValue) + parseFloat(gAmtValue)).toFixed(
//       2
//     );
//     setNetAmt(netAmount);
//   };

//   const isFormValid = () => {
//     return (
//       partyName.trim() &&
//       itemName.trim() &&
//       mark.trim() &&
//       bags.trim() &&
//       rate.trim() &&
//       partyNameValid &&
//       itemNameValid
//     );
//   };

//   const resetFields = () => {
//     setItemName("");
//     setPartyName("");
//     setMark("");
//     setTown("");
//     setBags("");
//     setWeightInput("0.000");
//     setRate("");
//     setAmount("0.00");
//     setCommission("0.00");
//     setCoolieCommission("0.00");
//     setGAmt(gAmtMultiplier.toString());
//     setNetAmt("0.00");
//     setFspNo("");
//     setBondNo("");
//     setSerialCount(0);
//     setDropdown(transactionTypes.length > 0 ? transactionTypes[0].ttId : "");
//     setFarmer("");
//     setactName("");
//     setItemNameValid(true);
//     setPartyNameValid(true);
//     setSelectedGrade("");
//     setGradeOptions([]);
//     setItemId("");
//     setModalData({});
//     setWeightEditable(false);
//     setSelectedDate(billDate);
//   };

//   const handleEdit = (index) => {
//     const item = transactionItems3[index];
//     setItemName(item.itemName || "");
//     setItemId(item.itemId || "");
//     setSelectedGrade(item.gradeName || "");
//     setPartyName(item.partyName || "");
//     setMark(item.mark || "");
//     setBags(item.bags || "");
//     setWeightInput(item.weight || "0.000");
//     setTareWeight(item.tareWeight || "0.00");
//     setNetWeight(item.netWeight || "0.00");
//     setRate(item.rate || "");
//     setAmount(item.amount || "0.00");
//     setCommission(item.commission || "0.00");
//     setCoolieCommission(item.coolieCommission || "0.00");
//     setGAmt(item.gAmt || gAmtMultiplier.toString());
//     setNetAmt(item.netAmt || "0.00");
//     setFspNo(item.fspNo || "");
//     setBondNo(item.bondNo || "");
//     setSerialCount(item.bags || 0);
//     setDropdown(item.dropdown || transactionTypes[0]?.ttId || "");
//     setTown(item.town || "");
//     setFarmer(item.farmer || "");
//     setactName(item.actName || "");
//     setModalData(item.modalData || {});
//     setEditIndex(index);
//     setWeightEditable(item.modalData.weights.length === 0);

//     setBillNo2(item.billNo2);
//     localStorage.setItem("billNo2", item.billNo2);
//   };

//   const handleDelete = (index) => {
//     const updatedItems = transactionItems3.filter((_, i) => i !== index);
//     setTransactionItems3(updatedItems);
//   };

//   const handlePartyNameChange = (e) => {
//     const value = e.target.value;
//     setPartyName(value);

//     if (value.length >= 3) {
//       fetchPartyNameSuggestions(value);
//     } else {
//       setSuggestions([]);
//     }
//   };

//   const handleItemNameChange = (e) => {
//     const value = e.target.value;
//     setItemName(value);

//     fetchItemNameSuggestions(value);

//     const filteredSuggestions = itemSuggestions.filter((suggestion) =>
//       suggestion.itemName.toLowerCase().includes(value.toLowerCase())
//     );

//     const matchesSuggestion = filteredSuggestions.some(
//       (suggestion) => suggestion.itemName === value
//     );

//     if (matchesSuggestion) {
//       const matchedItem = filteredSuggestions.find(
//         (suggestion) => suggestion.itemName === value
//       );
//       setGAmt(matchedItem.gAmt);
//       setItemId(matchedItem.itemId);
//     } else {
//       setGAmt("0");
//       setItemId("");
//     }

//     setItemNameValid(matchesSuggestion || value.length === 0);
//   };

//   const handleItemNameFocus = () => {
//     fetchItemNameSuggestions("");
//   };

//   const handlePartyNameBlur = () => {
//     if (suggestions.length > 0) {
//       const isPartyValid = suggestions.some(
//         (suggestion) => suggestion.AccountName === partyName
//       );
//       setPartyNameValid(isPartyValid || partyName.length === 0);
//     } else {
//       setPartyNameValid(true);
//     }
//   };

//   const handleItemNameBlur = () => {
//     setItemNameValid(
//       itemSuggestions.some((suggestion) => suggestion.itemName === itemName) ||
//         itemName.length === 0
//     );
//   };

//   const fetchItemNameSuggestions = async (query) => {
//     try {
//       const response = await api.get(`/Master/ListofItems?itemName=${query}`);
//       const mappedSuggestions = response.data.map((suggestion) => ({
//         itemName: suggestion.itemName,
//         gAmt: suggestion.gunnyAmt.toString(),
//         itemId: suggestion.itemId || suggestion.itemid || suggestion.id,
//       }));
//       setItemSuggestions(mappedSuggestions);

//       if (mappedSuggestions.length > 0) {
//         setGAmt(mappedSuggestions[0].gAmt);
//         // setGAmt(0);
//         setItemId(mappedSuggestions[0].itemId);
//       } else {
//         setGAmt("0");
//         setItemId("");
//       }
//     } catch (error) {
//       console.error("Error fetching item name suggestions", error);
//       setItemSuggestions([]);
//     }
//   };

//   const fetchPartyNameSuggestions = async (query) => {
//     try {
//       const response = await api.get(`/Master/Accounts?AccountName=${query}`);
//       const mappedSuggestions = response.data.map((suggestion) => ({
//         AccountName: suggestion.act_Name,
//         Town: suggestion.act_Town,
//       }));
//       setSuggestions(mappedSuggestions);
//     } catch (error) {
//       console.error("Error fetching party name suggestions", error);
//       setSuggestions([]);
//     }
//   };

//   const fetchCommissionAndCoolie = async () => {
//     try {
//       const response = await api.get("/Login/GetCommissionAndCooli");
//       if (response.data && response.data.length > 0) {
//         const { commission, cooli } = response.data[0];
//         setCommission(commission.toFixed(2));
//         setCoolieCommission(cooli.toFixed(2));
//       }
//     } catch (error) {
//       console.error("Error fetching commission and coolie commission", error);
//       setCommission("0.00");
//       setCoolieCommission("0.00");
//     }
//   };

//   const handleSearchChange = (e) => {
//     setSearchQuery(e.target.value);
//   };

//   const handleSaveAndUpdate = () => {
//     if (buttonText === "Update") {
//       const existingAcSaleData =
//         JSON.parse(localStorage.getItem("AcSale")) || [];
//       const updatedAcSaleData = [...existingAcSaleData, ...transactionItems3];
//       localStorage.setItem("AcSale", JSON.stringify(updatedAcSaleData));
//       localStorage.removeItem("transactionItems3");
//       setTransactionItems3([]);
//       setButtonText("Save & Clear");

//       generateNextBillNo();

//       resetFields();
//     } else {
//       handleSaveAndNew();
//     }
//   };

//   const handleSaveAndNew = async () => {
//     const currentDate = new Date().toISOString();
//     const itemsWithDate = transactionItems3.map((item) => ({
//       ...item,
//       currentDate,
//     }));

//     try {
//       const existingAcSaleData =
//         JSON.parse(localStorage.getItem("AcSale")) || [];

//       const updatedAcSaleData = [...existingAcSaleData, ...itemsWithDate];

//       localStorage.setItem("AcSale", JSON.stringify(updatedAcSaleData));

//       setTransactionItems3([]);

//       resetFields();

//       localStorage.removeItem("acSaleModalData");

//       generateNextBillNo();
//     } catch (error) {
//       console.error("Failed to save transaction data", error);
//     }
//   };

//   useEffect(() => {
//     if (itemId) {
//       fetchGradeOptions(itemId);
//     } else {
//       setGradeOptions([]);
//       setSelectedGrade("");
//     }
//   }, [itemId]);

//   const fetchGradeOptions = async (itemId) => {
//     try {
//       const response = await api.get(`/Master/GetCommodities?itemid=${itemId}`);

//       if (Array.isArray(response.data)) {
//         const grades = response.data.map((grade) => ({
//           gradeId: grade.cmdTypeID,
//           gradeName: grade.cmdDesc,
//         }));
//         setGradeOptions(grades);
//       } else {
//         console.error("Unexpected API response structure:", response.data);
//         setGradeOptions([]);
//       }
//     } catch (error) {
//       console.error("Error fetching grade options:", error);
//       setGradeOptions([]);
//     }
//   };

//   useEffect(() => {
//     const fetchGAmtMultiplier = async () => {
//       try {
//         const response = await api.get("/api/g-amt-multiplier");
//         setGAmtMultiplier(response.data.multiplier);
//         // setGAmtMultiplier(0);
//       } catch (error) {
//         console.error("Error fetching G Amt Multiplier", error);
//       }
//     };

//     const fetchTransactionTypes = async () => {
//       try {
//         const response = await api.get("/Master/Ttypes");
//         if (Array.isArray(response.data)) {
//           setTransactionTypes(response.data);
//           setDropdown(response.data.length > 0 ? response.data[0].ttId : "");
//         } else {
//           console.error("Unexpected response structure", response.data);
//           setTransactionTypes([]);
//         }
//       } catch (error) {
//         console.error("Error fetching transaction types", error);
//         setTransactionTypes([]);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchGAmtMultiplier();
//     fetchTransactionTypes();
//     fetchCommissionAndCoolie();

//     const token = localStorage.getItem("token");
//     if (!token) {
//       navigate("/login");
//     }

//     const savedCharges = localStorage.getItem("charges3");
//     if (savedCharges) {
//       setCharges3(JSON.parse(savedCharges));
//     }

//     const savedItems = localStorage.getItem("transactionItems3");
//     if (savedItems) {
//       setTransactionItems3(JSON.parse(savedItems));
//     }

//     fetchItemNameSuggestions("");
//   }, [navigate, api]);

//   useEffect(() => {
//     localStorage.setItem(
//       "transactionItems3",
//       JSON.stringify(transactionItems3)
//     );
//     localStorage.setItem("billNo2", billNo2);
//     localStorage.setItem("selectedDate", selectedDate);
//   }, [transactionItems3, billNo2, selectedDate]);

//   const filteredItems = transactionItems3.filter((item) =>
//     item.farmer.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   const groupedItems = filteredItems.reduce((groups, item) => {
//     const key = `${item.itemName}-${item.farmer}-${item.mark}`;
//     if (!groups[key]) {
//       groups[key] = [];
//     }
//     groups[key].push(item);
//     return groups;
//   }, {});

//   const handleJsonDownload = async (item) => {
//     if (!Capacitor.isNativePlatform()) {
//       const json = JSON.stringify(item, null, 2);
//       const blob = new Blob([json], { type: "application/json" });
//       const url = URL.createObjectURL(blob);
//       const a = document.createElement("a");
//       a.href = url;
//       a.download = `${item.billNo || item.billNo2 || "data"}.json`;
//       a.click();
//       URL.revokeObjectURL(url);
//       return;
//     }

//     try {
//       const json = JSON.stringify(item, null, 2);
//       const fileName = `${item.billNo || item.billNo2 || "data"}.json`;

//       const result = await Filesystem.writeFile({
//         path: fileName,
//         data: json,
//         directory: Directory.Documents,
//         encoding: Encoding.UTF8,
//       });

//       if (isCordovaPrinterAvailable()) {
//         window.cordova.plugins.printer.print(
//           `<pre>${json}</pre>`,
//           "Print JSON",
//           function (res) {
//             if (res) {
//               console.log("Print Successful");
//             } else {
//               console.error("Print Failed");
//               alert("Failed to print the document.");
//             }
//           }
//         );
//       } else {
//         console.error("Cordova Printer Plugin not available.");
//         alert("Printing is not supported on this device.");
//       }
//     } catch (error) {
//       console.error("Error downloading JSON:", error);
//       alert("Failed to download JSON file");
//     }
//   };

//   const handleExcelDownload = async () => {
//     if (!Capacitor.isNativePlatform()) {
//       try {
//         const { utils, write } = await import("xlsx");

//         let dataToDownload = transactionItems3;

//         const worksheet = utils.json_to_sheet(dataToDownload);
//         const workbook = utils.book_new();
//         utils.book_append_sheet(workbook, worksheet, "AcSale");

//         const excelBuffer = write(workbook, {
//           bookType: "xlsx",
//           type: "array",
//         });

//         const blob = new Blob([excelBuffer], {
//           type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//         });

//         const url = URL.createObjectURL(blob);
//         const a = document.createElement("a");
//         a.href = url;
//         a.download = `AcSale-${selectedDate}.xlsx`;
//         a.click();
//         URL.revokeObjectURL(url);
//       } catch (error) {
//         console.error("Error downloading Excel on web:", error);
//         alert("Failed to download Excel file");
//       }
//       return;
//     }

//     try {
//       const { utils, write } = await import("xlsx");

//       let dataToDownload = transactionItems3;

//       const worksheet = utils.json_to_sheet(dataToDownload);
//       const workbook = utils.book_new();
//       utils.book_append_sheet(workbook, worksheet, "AcSale");

//       const excelBuffer = write(workbook, { bookType: "xlsx", type: "array" });

//       const base64Data = Buffer.from(excelBuffer).toString("base64");

//       const fileName = `AcSale-${selectedDate}.xlsx`;

//       const result = await Filesystem.writeFile({
//         path: fileName,
//         data: base64Data,
//         directory: Directory.Documents,
//         encoding: Encoding.UTF8,
//       });

//       const excelContent = "Excel file content cannot be printed directly.";
//       if (isCordovaPrinterAvailable()) {
//         window.cordova.plugins.printer.print(
//           excelContent,
//           "Print Excel",
//           function (res) {
//             if (res) {
//               console.log("Print Successful");
//             } else {
//               console.error("Print Failed");
//               alert("Failed to print the document.");
//             }
//           }
//         );
//       } else {
//         console.error("Cordova Printer Plugin not available.");
//         alert("Printing is not supported on this device.");
//       }
//     } catch (error) {
//       console.error("Error downloading Excel:", error);
//       alert("Failed to download Excel file");
//     }
//   };

//   const handleDateChange = (e) => {
//     setSelectedDate(e.target.value);
//   };

//   const convertDateToISO = (date) => {
//     const [dd, mm, yyyy] = date.split("-");
//     const day = dd.padStart(2, "0");
//     const month = mm.padStart(2, "0");
//     return `${yyyy}-${month}-${day}`;
//   };

//   const filteredPurchaseList = [];
//   const filteredPlatformSaleList = [];
//   const filteredAcSaleList = transactionItems3.filter(
//     (item) => convertDateToISO(item.billDate) === convertDateToISO(selectedDate)
//   );

//   return (
//     <>
//       <header className="main-header">
//         <NavLink to="/">
//           <img src={logo} alt="Kliq Soft Logo" className="logo" />
//         </NavLink>
//       </header>
//       <main>
//         <div className="transactions">
//           <p>Transactions</p>
//           <p>
//             <img src={sale} alt="Ac Sale Icon" />
//             AcSale
//           </p>
//         </div>

//         <div className="goback">
//           <NavLink to="/">
//             <svg
//               fill="#185492"
//               className="left-arrow"
//               xmlns="http://www.w3.org/2000/svg"
//               width={22}
//               height={22}
//               viewBox="0 0 20 20"
//             >
//               <path d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm6,11H7.38l2.376,2.375L8.342,14.789,3.607,10.055,8.342,5.322,9.756,6.736,7.49,9H16Z" />
//             </svg>
//           </NavLink>
//         </div>

//         <div className="form-container clear">
//           {loading ? (
//             <p>Loading...</p>
//           ) : (
//             <form className="form form1">
//               <div className="input-box active-grey focus">
//                 <label className="input-label">Dropdown/select list</label>
//                 <select
//                   className="input-1"
//                   value={dropdown}
//                   ref={dropdownRef}
//                   onChange={(e) => setDropdown(e.target.value)}
//                 >
//                   {Array.isArray(transactionTypes) &&
//                   transactionTypes.length > 0 ? (
//                     transactionTypes.map((type) => (
//                       <option key={type.ttId} value={type.ttId}>
//                         {type.ttName}
//                       </option>
//                     ))
//                   ) : (
//                     <option>No options available</option>
//                   )}
//                 </select>
//               </div>

//               <div className="row row-bill-info">
//                 <div className="input-box col focus">
//                   <label className="input-label">Bill No.</label>
//                   <input
//                     type="text"
//                     className="input-1"
//                     value={billNo2}
//                     readOnly
//                   />
//                 </div>
//                 <div className="input-box col focus">
//                   <label className="input-label">Bill Date</label>
//                   <input
//                     type="text"
//                     className="input-1"
//                     value={billDate}
//                     readOnly
//                   />
//                 </div>
//               </div>

//               <div className="row">
//                 <div className="input-box col focus">
//                   <label
//                     className={`input-label ${
//                       !partyNameValid ? "label-invalid" : ""
//                     }`}
//                   >
//                     Party Name *
//                   </label>
//                   <input
//                     type="text"
//                     id="inputField"
//                     className={`input-1 ${
//                       !partyNameValid ? "input-invalid" : ""
//                     }`}
//                     ref={partyNameRef}
//                     required
//                     value={partyName || ""}
//                     onChange={handlePartyNameChange}
//                     onBlur={handlePartyNameBlur}
//                   />
//                   {suggestions.length > 0 && (
//                     <ul className="suggestions-list">
//                       {suggestions.map((suggestion, index) => (
//                         <li
//                           key={index}
//                           onClick={() => handleSuggestionClick(suggestion)}
//                         >
//                           {suggestion.AccountName}
//                         </li>
//                       ))}
//                     </ul>
//                   )}
//                   {!partyNameValid && (
//                     <div className="invalid-feedback">
//                       Party name does not exist.{" "}
//                       <NavLink to="/accountpage" className="add-new">
//                         Add New Account
//                       </NavLink>
//                     </div>
//                   )}
//                 </div>
//                 <div className="input-box col focus">
//                   <label className="input-label">Town</label>
//                   <input
//                     type="text"
//                     className="input-1"
//                     ref={townRef}
//                     value={town || ""}
//                     onChange={(e) => setTown(e.target.value)}
//                   />
//                 </div>
//               </div>
//               <div className="input-box focus">
//                 <label className="input-label">Item Name *</label>
//                 <select
//                   className={`input-1 ${!itemNameValid ? "input-invalid" : ""}`}
//                   ref={itemNameRef}
//                   value={itemName || ""}
//                   onChange={handleItemNameChange}
//                   onFocus={handleItemNameFocus}
//                   onBlur={handleItemNameBlur}
//                 >
//                   <option value="">Select an item</option>
//                   {itemSuggestions.map((suggestion, index) => (
//                     <option
//                       key={suggestion.itemId || index}
//                       value={suggestion.itemName}
//                     >
//                       {suggestion.itemName}
//                     </option>
//                   ))}
//                 </select>
//                 {!itemNameValid && (
//                   <div className="invalid-feedback">
//                     Item name does not exist.
//                   </div>
//                 )}
//               </div>

//               <div className="input-box focus">
//                 <label className="input-label">Grade Name</label>
//                 <select
//                   className="input-1"
//                   value={selectedGrade}
//                   onChange={(e) => setSelectedGrade(e.target.value)}
//                 >
//                   <option value="">Select Grade</option>
//                   {gradeOptions.map((grade) => (
//                     <option key={grade.gradeId} value={grade.gradeName}>
//                       {grade.gradeName}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             </form>
//           )}

//           <form className="form form2">
//             <div className="row row1">
//               <div className="input-box col col1 focus">
//                 <label className="input-label">Farmer Name</label>
//                 <input
//                   type="text"
//                   className="input-1"
//                   value={farmer}
//                   onChange={handleFarmerChange}
//                   placeholder="Start typing a farmer name"
//                 />
//                 {farmerSuggestions.length > 0 && (
//                   <ul className="suggestions-list">
//                     {farmerSuggestions.map((suggestion, index) => (
//                       <li
//                         key={index}
//                         onClick={() => handleFarmerSuggestionClick(suggestion)}
//                       >
//                         {suggestion.act_Name} - {suggestion.act_Town}
//                       </li>
//                     ))}
//                   </ul>
//                 )}
//               </div>
//               <div className="input-box col col2 focus">
//                 <label className="input-label">Mark *</label>
//                 <input
//                   type="text"
//                   className="input-1"
//                   ref={markRef}
//                   value={mark || ""}
//                   onChange={(e) => setMark(e.target.value)}
//                 />
//               </div>
//             </div>
//             <div className="row row2">
//               <div className="input-box col col1 focus">
//                 <label className="input-label">Bags *</label>
//                 <input
//                   type="text"
//                   className="input-1"
//                   ref={bagsRef}
//                   value={bags || ""}
//                   onChange={handleBagsChange}
//                   inputMode="numeric"
//                   onBlur={handleBagsBlur}
//                 />
//               </div>
//               <div className="input-box col col3 focus">
//                 <label className="input-label">Weight</label>
//                 <input
//                   type="text"
//                   className="input-1 numeric-input three-decimal"
//                   ref={weightRef}
//                   inputMode="numeric"
//                   value={weightInput}
//                   onInput={handleWeightChange}
//                   onBlur={() =>
//                     setWeightInput(formatToThreeDecimalPlaces(weightInput))
//                   }
//                   readOnly={!weightEditable}
//                 />
//               </div>
//             </div>
//             <div className="row row3">
//               <div className="input-box col col1 focus">
//                 <label className="input-label">Rate *</label>
//                 <input
//                   type="text"
//                   className="input-1 numeric-input"
//                   ref={rateRef}
//                   value={rate || ""}
//                   inputMode="numeric"
//                   onChange={handleRateChange}
//                 />
//               </div>
//               <div className="input-box col col2 focus">
//                 <label className="input-label">Amount</label>
//                 <input
//                   type="text"
//                   className="input-1 numeric-input"
//                   value={amount}
//                   readOnly
//                 />
//               </div>
//               <div className="input-box col col3 focus">
//                 <label className="input-label">Commission</label>
//                 <input
//                   type="text"
//                   className="input-1 numeric-input"
//                   value={commission}
//                   onChange={handleCommissionChange}
//                   onBlur={handleCommissionBlur}
//                 />
//               </div>
//             </div>
//             <div className="row row4">
//               <div className="input-box col col1 focus">
//                 <label className="input-label">Coolie Commission</label>
//                 <input
//                   type="text"
//                   className="input-1 numeric-input"
//                   value={coolieCommission}
//                   onChange={handleCoolieCommissionChange}
//                   onBlur={handleCoolieCommissionBlur}
//                 />
//               </div>
//               <div className="input-box col col2 focus">
//                 <label className="input-label">G.Amt</label>
//                 <input
//                   type="text"
//                   className="input-1 numeric-input"
//                   value={gAmt}
//                   readOnly
//                 />
//               </div>
//               <div className="input-box col col3 focus">
//                 <label className="input-label">Net Amt</label>
//                 <input
//                   type="text"
//                   className="input-1 numeric-input"
//                   value={netAmt}
//                   readOnly
//                 />
//               </div>
//             </div>
//             <div className="row row5">
//               <div className="input-box col col1 focus">
//                 <label className="input-label">FSP No.</label>
//                 <input
//                   type="text"
//                   className="input-1 numeric-input"
//                   value={fspNo}
//                   onChange={(e) => setFspNo(e.target.value)}
//                 />
//               </div>

//               <div className="input-box col col1 focus">
//                 <label className="input-label">Bond No.</label>
//                 <input
//                   type="text"
//                   className="input-1 numeric-input"
//                   value={bondNo}
//                   onChange={(e) => setBondNo(e.target.value)}
//                 />
//               </div>
//             </div>
//             <button
//               type="button"
//               id="submitButton"
//               className={`btn btn-primary pull-right ${
//                 !isFormValid() ? "btn-disabled" : ""
//               }`}
//               disabled={!isFormValid()}
//               onClick={handleAddItem}
//             >
//               {editIndex !== null ? "Update Item" : "Add Item"}
//             </button>
//             <div className="listAndSave">
//               <button
//                 type="button"
//                 className="btn btn-save"
//                 onClick={handleSaveAndUpdate}
//               >
//                 {buttonText}
//               </button>
//             </div>
//           </form>
//         </div>

//         <div
//           className="item-search"
//           style={{ marginBottom: filteredItems.length > 0 ? "0" : "3rem" }}
//         >
//           <input
//             type="text"
//             name="search"
//             id="search"
//             placeholder="Farmer Name"
//             value={searchQuery || ""}
//             onChange={handleSearchChange}
//           />
//           <button className="btn btn-search">View</button>
//         </div>

//         {filteredItems.length > 0 && (
//           <div
//             className="item-list"
//             style={{ overflowX: "auto", marginBottom: "3.5rem" }}
//           >
//             <table>
//               <thead>
//                 <tr>
//                   <th scope="col">Bags</th>
//                   <th scope="col">Weight</th>
//                   <th scope="col">Rate</th>
//                   <th scope="col">G.Amt</th>
//                   <th scope="col">Net Amt</th>
//                   <th scope="col">FSP No.</th>
//                   <th scope="col">Bond No.</th>
//                   <th
//                     scope="col"
//                     className="action"
//                     colSpan={3}
//                     style={{ opacity: 0 }}
//                   >
//                     Action
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {Object.entries(groupedItems).map(
//                   ([key, items], groupIndex) => (
//                     <React.Fragment key={`group-${groupIndex}`}>
//                       <tr className="trHeader">
//                         <td colSpan={8}>
//                           {items[0].itemName} - {items[0].farmer}
//                         </td>
//                         <td colSpan={5} className="mark">
//                           {items[0].mark}
//                         </td>
//                       </tr>
//                       {items.map((item, index) => (
//                         <tr key={`data-${groupIndex}-${index}`}>
//                           <td>{item.bags}</td>
//                           <td>{item.weight}</td>
//                           <td>{item.rate}</td>
//                           <td>{item.gAmt}</td>
//                           <td>{item.netAmt}</td>
//                           <td>{item.fspNo}</td>
//                           <td>{item.bondNo}</td>
//                           <td>
//                             <img
//                               id="itemImg"
//                               src={preview}
//                               alt="Preview Download"
//                               style={{ cursor: "pointer" }}
//                               onClick={() => handleShowPopup(item)}
//                             />
//                           </td>
//                           <td>
//                             <img
//                               src={editIcon}
//                               alt="Edit"
//                               onClick={() => handleEdit(index)}
//                               style={{ cursor: "pointer" }}
//                             />
//                           </td>
//                           <td>
//                             <img
//                               src={trashIcon}
//                               alt="Delete"
//                               onClick={() => handleDelete(index)}
//                               style={{ cursor: "pointer" }}
//                             />
//                           </td>
//                         </tr>
//                       ))}
//                     </React.Fragment>
//                   )
//                 )}
//               </tbody>
//               <tfoot>
//                 <tr>
//                   <th colSpan={10}>No. of Items: {transactionItems3.length}</th>
//                 </tr>
//               </tfoot>
//             </table>
//           </div>
//         )}
//       </main>
//       {showModal && (
//         <ModalWindow
//           serialCount={serialCount}
//           onClose={handleModalClose}
//           initialWeights={modalData.weights}
//           initialSerials={modalData.serialNumbers}
//           startSerial={modalData.startFrom}
//         />
//       )}
//       {chargeModalOpen && (
//         <ChargeModal
//           isOpen={chargeModalOpen}
//           onClose={() => setChargeModalOpen(false)}
//           chargesData={charges3}
//           onSave={(data) => setCharges3(data)}
//         />
//       )}
//       {showAlert && (
//         <>
//           <div
//             className="alert-popup-overlay"
//             onClick={() => setShowAlert(false)}
//           ></div>
//           <div className="alert-popup">
//             <p>Party Name already exists on this day.</p>
//             <button onClick={handlePopupConfirm}>OK</button>
//           </div>
//         </>
//       )}
//       {showPopup && (
//         <WeightListPopup
//           onClose={handleClosePopup}
//           itemData={selectedItemData}
//         />
//       )}
//     </>
//   );
// }

// export default AcSale;
